(function () {
    'use strict';

    angular.module('app').component('hostCorporatesSupportPlansCreateEditScheduleOfBenefitComplicationsComponent', {
        templateUrl: require('/App/host/views/corporates/supportPlans/createEdit/scheduleOfBenefit/complications/complications.component.html'),
        controller: ['$sce', controller],
        controllerAs: 'vm',
        bindings: {
            hasMultipleVersion: '<',
            scheduleOfBenefit: '<',
            currencyCode: '<'
        }
    });

    function controller($sce) {
        var vm = this;
        vm.miscarriageText = App.localize('Miscarriage');
        vm.coversPregnancyRelatedComplicationsText = App.localize('CoversPregnancyRelatedComplications');
        vm.perAnnumText = App.localize('PerAnnum');

        vm.displaySafeHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

        vm.toggleMiscarriage = function () {
            vm.minMiscarriageAmount = vm.scheduleOfBenefit.miscarriage.amountType === 1 ? 1 : 0;
        };

        vm.toggleComplications = function () {
            if (vm.scheduleOfBenefit.isDefaultExclusionsCovered === 1) {
                vm.scheduleOfBenefit.isOptionalExclusionsCovered = 
                vm.scheduleOfBenefit.coveredOptionalExclusions.length > 0 ? 1 : 0;
            }
            else {
                vm.scheduleOfBenefit.isOptionalExclusionsCovered = 0;
            }
        };

        function init() {
            vm.toggleMiscarriage();
            vm.toggleComplications();
        }

        angular.element(document).ready(function () {
            init();
        });
    }
})();
