(() => {
  angular
    .module('app')
    .controller(
      'modules.finance.views.myInvois.viewPayloadModal',
      ViewPayloadModalController
    );

  ViewPayloadModalController.$inject = ['$uibModalInstance', 'responseBody'];

  function ViewPayloadModalController($uibModalInstance, responseBody) {
    const vm = this;

    vm.responseBody = JSON.stringify(responseBody, null, 2);
    vm.close = close;

    function close() {
      $uibModalInstance.dismiss();
    }
  }
})();
