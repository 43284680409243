import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.myInvois.sandbox', MyInvoisSandboxController);

  MyInvoisSandboxController.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'abp.services.finance.myInvois',
    'abp.services.finance.saleTransactions'
  ];

  function MyInvoisSandboxController(
    $scope,
    $stateParams,
    $timeout,
    $uibModal,
    financeConsts,
    commonLookupSvc,
    myInvoisSvc,
    saleTransactionsSvc
  ) {
    const vm = this;
    vm.tabIndex = Number(0);
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getSettings)
        );
      },
      data: [],
    };

    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;
    vm.setActiveView = setActiveView;
    vm.getSettings = getSettings;
    vm.generatePayload = generatePayload;
    vm.submitDocument = submitDocument;

    init();

    // Init function.

    function init() {
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    function getRecentDocuments() {
      if (!vm.hasMyInvoisSubmission)
        return;

      var input = vm.requestParams.toStateParams();
      vm.loading += 1;
      myInvoisSvc
        .getRecentDocuments($.extend(input, {isSandbox: true}))
        .success((data) => {
          vm.gridOptions.data = data.items;
          vm.gridOptions.totalItems = data.totalCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasMyInvoisSubmission = data.myInvoisSubmission;
          if (vm.hasMyInvoisSubmission) getRecentDocuments();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function generatePayload() {
      vm.loading += 1;
      saleTransactionsSvc
        .generateMyInvoisPayload({
          transactionNumber: vm.transactionNumber
        })
        .success((data) => {
          vm.payload = data.output;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function submitDocument() {
      vm.loading += 1;
      myInvoisSvc
        .submitDocument({
          payload: vm.payload,
          transactionNumber: vm.transactionNumber,
          isSandbox: true
        })
        .success((data) => {
          $uibModal.open({
            templateUrl: require('./submissionResponseModal.html'),
            controller: 'modules.finance.views.myInvois.submissionResponseModal as vm',
            backdrop: 'static',
            resolve : {
              responseBody: () => data
            }
          })
          .result.then(() => {
            getRecentDocuments();
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('TransactionNumber'),
          enableSorting: false,
          field: 'internalId',
          minWidth: 100
        },
        {
            displayName: App.localize('SubmissionId'),
            enableSorting: false,
            field: 'submissionUid',
            minWidth: 100
        },
        {
            displayName: App.localize('DocumentId'),
            enableSorting: false,
            field: 'uuid',
            minWidth: 100
        },
        {
            displayName: App.localize('Status'),
            enableSorting: false,
            field: 'status',
            minWidth: 100,
            cellTemplate: 'statusTemplate'
        },
        {
          displayName: App.localize('Amount'),
          enableSorting: false,
          field: 'totalSales',
          minWidth: 100
        }
      ];

      return columnDefs;
    }

    function setActiveView(viewId) {
      vm.tabIndex = Number(viewId);
      vm.gridOptions.paginationPageSize = financeConsts.grid.defaultPageSize;
      vm.gridOptions.paginationCurrentPage = 1;
      vm.gridOptions.columnDefs = getGridOptionsColumnDefs(viewId);
    }

    function RequestParamsModel(params) {
      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.pageNo = this.skipCount / this.maxResultCount + 1;
      this.pageSize = this.maxResultCount;

      this.toStateParams = () => {
        const stateParams = {
          pageNo: vm.gridOptions.paginationCurrentPage,
          pageSize: vm.gridOptions.paginationPageSize,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting
        };
        return stateParams;
      };
    }
  }
})();
