(() => {
  angular
    .module('app')
    .component('commonViewsPatientTicketsConsultationDiagnosisDropdownComponent', {
      templateUrl: require('./consultationDiagnosis.component.html'),
      controller: ConsultationDiagnosisDropdownController,
      controllerAs: 'vm',
      bindings: {
        ticket: '<',
        currencyCode: '<',
        icpcs: '<',
      },
    });

  ConsultationDiagnosisDropdownController.$inject = [
    '$filter',
    'abp.services.app.diseaseClassification',
  ];

  function ConsultationDiagnosisDropdownController($filter, diseaseClassificationSvc) {
    const vm = this;

    vm.hasIcd10 = abp.setting.getBoolean('Hms.Feature.Icd10ForGpDental');

    vm.diseaseClassifications = [];
    vm.commonKeywords = [];
    vm.filteredDiagnosis = [];

    vm.showDropdownSearchNarrative = false;
    vm.showDropdownEmptyNarrative = false;
    vm.dropdownTouched = false;
    const itemsPerShow = 15;
    let maxConsultationAverageAmount = 0;
    let consultationWarningMessage = '';
    const priceRangeMargin = parseFloat(abp.setting.get('Hms.General.GpAveragePriceRangeMargin'));

    vm.$onChanges = onChanges;
    vm.onUpdate = onUpdate;
    vm.tagTransform = tagTransform;

    vm.lookupIcpcsDiagnosis = lookupIcpcsDiagnosis;
    vm.lookupDiagnosis = lookupDiagnosis;
    vm.selectItem = selectItem;
    vm.validateDropdown = validateDropdown;

    init();

    function init() {
      if (vm.hasIcd10) {
        getDiseaseClassifications();
      }
    }

    function onChanges(changes) {
      if (changes.consultationAveragePrice && changes.consultationAveragePrice.currentValue) {
        calculateConsultationAveragePriceRange();
        vm.onUpdate(vm.ticket.consultation);
      }
    }

    function onUpdate(record) {
      vm.warningMessage = '';
      if (record && record.amount > maxConsultationAverageAmount && consultationWarningMessage) {
        vm.warningMessage = consultationWarningMessage;
      }
    }

    function tagTransform(newTag) {
      return {
        inclusion: newTag,
      };
    }

    function getDiseaseClassifications() {
      vm.loading += 1;
      diseaseClassificationSvc.getDiseaseClassifications().success((data) => {
        vm.diseaseClassifications = data.items;

        vm.diseaseClassifications = _.map(vm.diseaseClassifications, (item) => {
          const [keyword, malayKeyword] = item.tags.split(',').map((word) => word.trim());
          const codeDesc = `${item.code} ${item.description}`;
          return _.assign({}, item, { keyword, malayKeyword, codeDesc });
        });

        // Group common classification keywords.

        vm.commonKeywords = _.chain(vm.diseaseClassifications)
          .map((item) => {
            const descriptions = item.tags.split(',').map((desc) => desc.trim());
            return descriptions[1]
              ? {
                  code: 'common',
                  description: descriptions[1],
                  codeDesc: `common ${descriptions[1]}`,
                  noShow: true,
                }
              : null;
          })
          .filter((desc) => desc)
          .uniqBy('description')
          .value();

        vm.commonKeywords.unshift({
          code: 'popularDiagnosis',
          description: 'Popular diagnosis',
          noShow: true,
        });

        prefillSymptoms();
      });
    }

    function prefillSymptoms() {
      const splitItems = vm.ticket.consultation.symptoms
        .flat()
        .flatMap((item) => item.split(',').map((str) => str.trim()));
      vm.ticket.consultation.symptoms = _.map(splitItems, (item) => {
        const [code, ...desc] = item.split(' ');
        const description = desc.join(' ');
        const match = _.find(vm.diseaseClassifications, { code });
        return match ? _.assign({}, match) : `${code} ${description}`;
      });
    }

    let previousSearch = '';
    let isShowMore = false;
    function selectItem(item) {
      const lastSelected = _.last(item.selected);

      switch (lastSelected.code) {
        case 'common':
          item.search = lastSelected.description;
          clearKeywords(item.selected, 'common');
          break;
        case 'showMore':
          isShowMore = true;
          loadItems(item);
          clearKeywords(item.selected, 'showMore');
          break;
        default:
          isShowMore = false;
          break;
      }
    }

    let currentPage = 0;
    let sortedDiagnosis = [];

    function lookupDiagnosis(item) {
      vm.dropdownTouched = true;

      const keyword = _.trim(item.search || '');

      if (isShowMore) return;

      vm.showDropdownSearchNarrative = false;

      if (!keyword) {
        // Set common keywords.

        vm.filteredDiagnosis = vm.commonKeywords;
      } else if (keyword) {
        // Filter.

        sortedDiagnosis = filterDiagnosis(keyword);

        if (sortedDiagnosis.length > itemsPerShow) {
          loadItems(item);
        } else {
          vm.filteredDiagnosis = sortedDiagnosis;
        }

        vm.showDropdownSearchNarrative = vm.filteredDiagnosis.length < 1;
      }
      isShowMore = false;
    }

    function filterDiagnosis(keyword) {
      const filteredDiagnosis = [];

      const regexes = _.chain(keyword.split(' '))
        .map((k) => _.trim(k.toUpperCase()))
        .filter((k) => k)
        .uniq()
        .map((k) => {
          const escaped = _.escapeRegExp(k);
          return {
            token: k,
            len: k.length,
            pattern: new RegExp(`${escaped}`, 'i'),
          };
        })
        .value();

      _.forEach(vm.diseaseClassifications, (x) => {
        let score = 0;
        _.forEach(regexes, (r) => {
          if (r.pattern.test(x.description)) {
            score -= r.len;
          }

          if (r.pattern.test(x.code)) {
            score -= r.len;
          }

          if (r.pattern.test(x.keyword)) {
            score -= r.len;
          }

          if (r.pattern.test(x.malayKeyword)) {
            score -= r.len;
          }
        });

        if (score) {
          filteredDiagnosis.push({
            score,
            item: x,
          });
        }
      });

      sortedDiagnosis = _.chain(filteredDiagnosis)
        .sortBy(['score'])
        .map((k) => k.item)
        .uniqBy((s) => s.code)
        .value();

      currentPage = 0;

      return sortedDiagnosis;
    }

    function lookupIcpcsDiagnosis(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        const filteredDiagnosis = [];
        const regexes = _.chain(keyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => {
            _.escapeRegExp(k);
            return {
              token: k,
              len: k.length,
              pattern: new RegExp(`\\b${k}`, 'i'),
            };
          })
          .value();
        _.forEach(vm.icpcs, (symptom) => {
          let score = 0;
          _.forEach(regexes, (r) => {
            if (r.pattern.test(symptom.inclusion)) {
              score -= r.len;
            }
            if (r.pattern.test(symptom.title)) {
              score -= r.len;
            }
          });
          if (score) {
            filteredDiagnosis.push({
              score,
              item: symptom,
            });
          }
        });
        vm.filteredDiagnosis = _.chain(filteredDiagnosis)
          .sortBy(['score'])
          .take(30)
          .map((k) => k.item)
          .uniqBy((s) => s.inclusion)
          .value();
      } else {
        vm.filteredDiagnosis = _.take(vm.icpcs, vm.limitSize);
      }
    }

    function loadItems(item) {
      const startIndex = currentPage * itemsPerShow;
      const endIndex = startIndex + itemsPerShow;

      const itemsToLoad = sortedDiagnosis.slice(startIndex, endIndex);

      const showShowMore = endIndex < sortedDiagnosis.length;

      if (currentPage === 0) {
        vm.filteredDiagnosis = itemsToLoad;
        previousSearch = item.search;
      } else {
        item.search = previousSearch;
        vm.filteredDiagnosis = _.concat(vm.filteredDiagnosis, itemsToLoad);
      }

      resetShowMore(showShowMore);
      currentPage += 1;
    }

    function resetShowMore(isShow) {
      const code = 'showMore';
      const desc = 'Show more';

      _.remove(vm.filteredDiagnosis, { code });

      if (isShow) {
        vm.filteredDiagnosis.push({
          code,
          description: desc,
          codeDesc: `${code} ${desc}`,
          noShow: true,
        });
      }
    }

    function clearKeywords(selected, keyword) {
      _.remove(selected, { code: keyword });

      _.remove(vm.ticket.consultation.symptoms, (item) => item.trim().split(' ')[0] === keyword);
    }

    function calculateConsultationAveragePriceRange() {
      if (!vm.consultationAveragePrice || !vm.consultationAveragePrice.amount) return;

      const consultationAverageAmount =
        vm.ticket.hasMidnightSurcharge && vm.ticket.midnightSurchargeRate
          ? vm.consultationAveragePrice.amount * vm.ticket.midnightSurchargeRate
          : vm.consultationAveragePrice.amount;

      // Calculate price range based on ±margin of consultation average amount.

      const minAmount = Math.round(consultationAverageAmount * (1 - priceRangeMargin));
      maxConsultationAverageAmount = Math.round(consultationAverageAmount * (1 + priceRangeMargin));

      consultationWarningMessage = App.localize(
        'OverchargeConsultation',
        $filter('currencyFormat')(minAmount, vm.currencyCode),
        $filter('currencyFormat')(maxConsultationAverageAmount, vm.currencyCode)
      );
    }

    function validateDropdown() {
      if (vm.dropdownTouched)
        vm.showDropdownEmptyNarrative = vm.ticket.consultation.symptoms.length < 1;
    }
  }
})();
