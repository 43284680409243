(function () {
    var controllerId = 'host.views.clinics.index';
    var app = angular.module('app');
    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', '$uibModal', '$timeout', 'abp.services.app.clinic', 'abp.services.app.commonLookup', 'lookupModal', 'countries', 'Hms.MultiTenancy.TenantClinicType', 'authentication', 'Hms.Finance.TransactionTenantType',
        function ($scope, $state, $stateParams, $uibModal, $timeout, clinicSvc, commonLookupSvc, lookupModal, countries, enumTenantClinicType, authentication, enumTransactionTenantType) {
            function init() {
                $timeout(function () {
                    vm.clinicGridOptions.paginationCurrentPage = Math.floor(viewOptions.skipCount / viewOptions.maxResultCount + 1);
                });

                if (vm.hasRegionalSystemSupport) {
                    vm.loading++;
                    commonLookupSvc.getEligibleCountries().success(function (data) {
                        var elligibleCountryCodes = data.map(o => o.code)

                        vm.countries = _.filter(countries, function (c) {
                            return elligibleCountryCodes.includes(c.code)
                        });
                    }).finally(function (e) {
                        vm.loading--;
                    });
                }
            }

            var vm = this;
            vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
            vm.loading = 0;
            vm.filterText = $stateParams.filterText || '';
            vm.clinicType = $stateParams.clinicType || '';
            vm.exporting = 0;
            vm.hasSingaporeInvoiceGeneration = abp.setting.getBoolean('Hms.Feature.SingaporeInvoiceGeneration');

             // Check for inpatient module enable for the application.

             vm.applicationHasInpatientModule = abp.setting.getBoolean('Hms.Feature.InpatientModule');

             vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

            vm.enums = {};
            vm.enums.tenantClinicType = enumTenantClinicType;
            vm.enums.transactionTenantType = enumTransactionTenantType;
            vm.createManualTransactionRequest = createManualTransactionRequest;

            $scope.$on('$viewContentLoaded', function () {
                App.initAjax();
            });

            // Assign permissions

            vm.permissions = {
                create: abp.auth.isGranted('Host.Clinics.Create'),
                createOverride: abp.auth.isGranted('Host.Clinics.CreateOverride'),
                edit: abp.auth.isGranted('Host.Clinics.Edit'),
                changeTreatmentRates: abp.auth.isGranted('Host.Clinics.ChangeTreatmentRates'),
                'delete': abp.auth.isGranted('Host.Clinics.Delete'),
                saleTransactions: abp.auth.isGranted('SaleTransactions'),
                generateSaleTransactions: abp.auth.isGranted('Host.Clinics.GenerateSaleTransactions'),
                patientTickets: abp.auth.isGranted('PatientTickets'),
                impersonation: abp.auth.isGranted('Host.Clinics.Impersonation'),
                viewLocations: abp.auth.isGranted('Host.Clinics.Locations'),
                resetAdminPassword: abp.auth.isGranted('Host.Clinics.ResetAdminPassword'),
                licensingFee: abp.auth.isGranted('Host.Clinics.LicensingFees'),
                createManualTransaction: abp.auth.isGranted('HealthMetrics.Finance.ManualTransactionRequests.Create')
            };
            vm.permissions.resetAdminPassword |= vm.permissions.edit;

            var zendeskSubdomain = abp.setting.get('Hms.Integration.Zendesk.Subdomain');
            vm.hasZendeskIntegration = zendeskSubdomain && zendeskSubdomain !== '';

            // Define grid options

            var viewOptions = {
                skipCount: $stateParams.skipCount || 0,
                maxResultCount: $stateParams.maxResultCount || app.consts.grid.defaultPageSize,
                sorting: $stateParams.sorting
            };

            vm.clinicGridOptions = {
                appScopeProvider: vm,
                paginationPageSizes: app.consts.grid.defaultPageSizes,
                paginationPageSize: app.consts.grid.defaultPageSize,
                paginationCurrentPage: 0,
                useExternalPagination: true,
                useExternalSorting: true,
                enableRowSelection: true,
                enableSelectAll: false,
                enableFullRowSelect: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'text-muted\': !row.entity.isActive }"  ui-grid-cell><\/div>',
                columnDefs: [{
                    name: App.localize('Actions'),
                    enableSorting: false,
                    width: 100,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate',
                    allowCellFocus: false
                }, {
                    displayName: App.localize('Warning'),
                    enableSorting: false,
                    field: 'warnings',
                    width: 25,
                    cellTemplate: 'warningsTemplate'
                }, {
                    displayName: App.localize('Country'),
                    field: 'accountOwnerCountryName',
                    width: 30,
                    visible: vm.hasRegionalSystemSupport,
                    cellTemplate: 'countryTemplate'
                }, {
                    displayName: App.localize('PanelCode'),
                    field: 'tenancyName',
                    minWidth: 120
                }, {
                    displayName: App.localize('Type'),
                    field: 'clinicType',
                    cellTemplate: 'clinicTypeTemplate',
                    width: 50
                }, {
                    displayName: App.localize('Services'),
                    field: 'services',
                    visible: vm.applicationHasInpatientModule,
                    cellTemplate: 'serviceTemplate',
                    width: 100
                }, {
                    displayName: App.localize('PanelCompanyName'),
                    field: 'name',
                    minWidth: 120
                }, {
                    displayName: App.localize('ContactNumber'),
                    field: 'contactNumber',
                    minWidth: 100
                }, {
                    displayName: App.localize('UnbilledAmount'),
                    field: 'unbilledAmount',
                    cellFilter: 'currencyFormat: row.entity.currencyCode',
                    enableSorting: false,
                    minWidth: 100
                }, {
                    displayName: App.localize('IsActive'),
                    field: 'isActive',
                    cellTemplate: 'activeTemplate',
                    minWidth: 65
                }, {
                    displayName: App.localize('StartDate'),
                    field: 'startDate',
                    cellTemplate: 'startDateTemplate',
                    minWidth: 100
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: "momentFormat: 'L'",
                    minWidth: 100
                }, {
                    displayName: App.localize('Remarks'),
                    field: 'remarks',
                    minWidth: 100
                }],
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.sortChanged($scope, function (scope, callback) {
                        var sorting = '';
                        for (var i = 0; i < callback.length; i++) {
                            if (i) sorting += ', ';
                            sorting += callback[i].field + ' ' + callback[i].sort.direction;
                        }
                        viewOptions.sorting = sorting;
                        vm.getClinics();
                    });
                    gridApi.pagination.on.paginationChanged($scope, App.setPagination(viewOptions, vm.getClinics));
                    gridApi.selection.on.rowSelectionChanged($scope, function (row, evt) {
                        if (vm.permissions.viewLocations) {
                            if (row.isSelected) {
                                $state.go('host.clinics.locations', {
                                    id: row.entity.id
                                });
                                vm.gridSelectedId = row.entity.id;
                            }
                            else {
                                $state.go('host.clinics');
                                vm.gridSelectedId = null;
                            }
                        }
                    });
                },
                data: []
            };
            vm.gridSelectedId = parseInt($stateParams.id) || null;

            vm.getDateString = getDateString;

            // Define functions

            function registerStateParams() {
                $state.transitionTo($state.current, {
                    skipCount: viewOptions.skipCount,
                    maxResultCount: viewOptions.maxResultCount,
                    sorting: viewOptions.sorting,
                    filterText: vm.filterText,
                    clinicType: vm.clinicType,
                    country: vm.countryCode,
                    id: $stateParams.id
                }, {
                    notify: false
                });
            }

            function getRequestInput() {
                registerStateParams();

                return {
                    skipCount: viewOptions.skipCount,
                    maxResultCount: viewOptions.maxResultCount,
                    sorting: viewOptions.sorting,
                    filter: vm.filterText,
                    clinicType: vm.clinicType,
                    countryCode: vm.countryCode
                };
            }

            vm.getClinics = function () {
                var input = getRequestInput();

                vm.loading++;
                clinicSvc.getClinics(input).success(function (data) {
                    _.forEach(data.items, function (d) {
                        d.canChangeRates = vm.permissions.changeTreatmentRates

                        var country = _.find(countries, ['code', d.accountOwnerCountryCode])

                        d.currencyCode = country.currencyCode;
                    });

                    vm.clinicGridOptions.totalItems = data.totalCount;
                    vm.clinicGridOptions.data = data.items;

                    $scope.gridApi.grid.modifyRows(vm.clinicGridOptions.data);
                    if (vm.gridSelectedId) {
                        for (var i = vm.clinicGridOptions.data.length - 1; i >= 0; i--) {
                            if (vm.clinicGridOptions.data[i].id === vm.gridSelectedId) {
                                $scope.gridApi.selection.selectRow(vm.clinicGridOptions.data[i]);
                                break;
                            }
                        }
                    }
                }).finally(function (e) {
                    vm.loading--;
                });
            };

            vm.manageLocations = function (clinic) {
                $state.go('host.clinics.locations', {
                    id: clinic.id
                });
            };

            vm.resetAdminPassword = function (clinic) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/clinics/resetAdminPassword/resetAdminPassword.modal.html'),
                    controller: "host.views.clinics.resetAdminPasswordModel as vm",
                    backdrop: "static",
                    resolve: {
                        tenancyName: function () {
                            return clinic.tenancyName;
                        },
                        panelId: function () {
                            return clinic.id;
                        }
                    }
                });
            };

            vm.unlockAdminUser = function (panel) {
                clinicSvc.unlockAdminUser({
                    id: panel.id
                }).success(function (data) {
                    abp.notify.success(App.localize("UserSuccessfullyUnlocked"));
                });
            }

            vm.editTreatmentRates = function (clinic) {
                $uibModal.open({
                    templateUrl: require('/App/host/views/clinics/ratesModal.html'),
                    controller: 'host.views.clinics.ratesModal as vm',
                    backdrop: 'static',
                    resolve: {
                        clinic: function () {
                            return clinic;
                        }
                    }
                });
            };

            vm.deletePanel = function (clinic) {
                abp.message.confirm(App.localize('PanelDeleteWarningMessage', clinic.tenancyName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        clinicSvc.deletePanel({
                            id: clinic.id
                        }).success(function (data) {
                            vm.getClinics();
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            vm.impersonate = function (clinic) {
                lookupModal.open({
                    title: App.localize('SelectAUser'),
                    serviceMethod: commonLookupSvc.findUsersWithIdentityServerId,
                    extraFilters: {
                        tenantId: clinic.id
                    },
                    callback: function (user) {
                        authentication.impersonate(user.value, user.targetTenantId);
                    }
                });
            };

            vm.generateStatement = function (clinic) {
                var modal = $uibModal.open({
                    templateUrl: require('/App/common/views/saleTransactions/createStatementModal.html'),
                    controller: 'common.views.saleTransactions.createStatementModal as vm',
                    backdrop: 'static',
                    resolve: {
                        tenantId: function () {
                            return clinic.id;
                        },
                        subsidiaryId: null
                    }
                });
                modal.result.then(function (input) {
                    $state.go('statement', input);
                });
            };

            vm.generateSaleTransactions = function (clinic) {
                abp.message.confirm(App.localize('GenerateSaleTransactionsWarningMessage', clinic.tenancyName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        clinicSvc.generateSaleTransactions({
                            id: clinic.id
                        }).success(function (data) {
                            vm.getClinics();
                            abp.notify.success(App.localize('SaleTransactionsGenerated'));
                        });
                    }
                });
            };

            vm.initializeZendeskOrganization = function (clinic) {
                abp.message.confirm(App.localize('InitializeZendeskOrganizationWarningMessage', clinic.tenancyName), App.localize('AreYouSure'), function (result) {
                    if (result) {
                        clinicSvc.initializeZendeskOrganization({
                            id: clinic.id
                        }).success(function () {
                            vm.getClinics();
                            abp.notify.success(App.localize('InitializeZendeskOrganizationRequestSent'));
                        });
                    }
                });
            };

            vm.exportToExcel = function () {
                var input = getRequestInput();

                vm.exporting++;
                clinicSvc.getClinicsToExcel(input).success(function (data) {
                    App.downloadTempFile(data);
                }).finally(function () {
                    vm.exporting--;
                });
            };

            vm.isAllowedForInvoiceGeneration = function (clinic) {
                return vm.hasSingaporeInvoiceGeneration || clinic.accountOwnerCountryCode !== "SG";
            }

            function createManualTransactionRequest(clinic) {
                $state.go('finance.createEditManualTransactionRequest', {
                  isCreate: true,
                  tenancyName: clinic.tenancyName,
                  transactionTenantType: vm.enums.transactionTenantType.Panel.id,
                });
            }

            function getDateString(date) {
                return App.getDateString(moment.utc(date));
            }

            init();
        }
    ]);
})();
