(() => {
  angular
    .module('app')
    .controller('host.views.accountOwners.createEdit', AccountOwnerCreateEditController);

  AccountOwnerCreateEditController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    'abp.services.app.accountOwner',
    'abp.services.app.commonLookup',
    'countries',
    'Hms.AccountOwners.PaymentAccountNumberMode',
    'AccountOwner',
    'Hms.MultiTenancy.Tenant',
    'Hms.PaymentAccounts.PaymentAccount',
  ];

  function AccountOwnerCreateEditController(
    $scope,
    $stateParams,
    $state,
    accountOwnerSvc,
    commonLookupSvc,
    countries,
    enumPaymentAccountNumberMode,
    constsAccountOwner,
    constsTenant,
    constsPaymentAccount
  ) {
    const vm = this;

    vm.constsAccountOwner = constsAccountOwner;
    vm.constsTenant = constsTenant;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.loading = 0;
    vm.saving = 0;
    vm.countries = [];
    vm.countryCodes = [];
    vm.selectedCountry = 'MY';
    vm.currencyCodes = [];
    vm.isCreate = !$stateParams.id;
    vm.isEdit = false;
    vm.dataRetrieved = false;
    vm.emailPattern = App.getEmailRegex();
    vm.countryCode = $stateParams.countryCode;

    vm.data = {
      countryCode: vm.selectedCountry,
      businessAddress: {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      },
      accountHolderRegisteredAddress: {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      },
      bankAddress: {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      },
    };

    vm.requestParams = {
      accountOwnerId: $stateParams.id,
    };

    vm.enums = {
      paymentAccountNumberMode: enumPaymentAccountNumberMode,
    };

    vm.hasIndonesiaAndThailandRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.IndonesiaAndThailandRegionalExpansion'
    );

    vm.updateCountry = updateCountry;
    vm.getCountryName = getCountryName;
    vm.saveAll = saveAll;
    vm.toggleEdit = toggleEdit;
    vm.cancel = cancel;

    init();

    function init() {
      getAccessibleCountries();
      getTimeZones();
      getCurrencyCodes();
      getHmsLogoUrl();

      // Get account owner details

      if (!vm.isCreate) {
        getAccountOwner(vm.requestParams.accountOwnerId);
      }
    }

    function getAccountOwner(id) {
      vm.loading += 1;
      accountOwnerSvc
        .getAccountOwner({id})
        .success((data) => {
          vm.data = data;
          vm.uploadedImage = data.imageUrl;
          vm.selectedCountry = data.countryCode;
          vm.dataRetrieved = true;

          getCurrencyCodes();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccessibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
          const eligibleCountryCodes = data.map((o) => o.code);
          vm.countryCodes = _.filter(countries, (c) => eligibleCountryCodes.includes(c.code));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getTimeZones() {
      vm.loading += 1;
      commonLookupSvc
        .getTimeZones()
        .success((data) => {
          vm.timeZones = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getCurrencyCodes() {
      vm.loading += 1;
      const currencyIsExist = (country) =>
        country.code === vm.selectedCountry && country.currencyCode != null;
      vm.currencyCodes = countries.filter((c) => currencyIsExist(c)).map((c) => c.currencyCode);
      vm.loading -= 1;
    }

    function getHmsLogoUrl() {
      vm.loading += 1;
      commonLookupSvc
        .getHmsLogoUrl()
        .success((data) => {
          vm.companyLogoUrlNarrative = App.localize('CompanyLogoUrlNarrative', data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateCountry() {
      // Set country code to account owner.

      vm.data.countryCode = vm.selectedCountry;

      // Re-initialize business address after changing country.

      vm.data.businessAddress = {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      };

      // Re-initialize bank address after changing country.

      vm.data.accountHolderRegisteredAddress = {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      };

      vm.data.bankAddress = {
        street: '',
        city: '',
        state: '',
        countrySubdivisionId: '',
        postcode: '',
        countryCode: vm.selectedCountry,
      };

      getCurrencyCodes();
    }

    function getCountryName() {
      const country = _.find(vm.countries, ['code', vm.selectedCountry]);
      if (country == null) return '';
      return country.name;
    }

    function resizeImage(data) {
      const canvas = document.createElement('canvas');

      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
      };
      img.src = data;
    }

    // Check form validity.
    function validateForm() {
      const form = $scope.accountOwnerForm;
      return App.isFormValid(form);
    }

    // Save account owner.
    function saveAll() {
      if (!validateForm()) {
        abp.message.warn(App.localize('OneOrMoreInputValueIsInvalid'));
        return;
      }

      /* Currently, SG addresses does not have the additional field on the UI
      (i.e. State, City/Town), which does not cleanly save all fields for
      optional address when overriding them in database. */

      if (
        vm.data.accountHolderRegisteredAddress.countryCode === 'SG' &&
        vm.data.accountHolderRegisteredAddress.postcode === ''
      ) {
        vm.data.accountHolderRegisteredAddress = {
          street: vm.data.accountHolderRegisteredAddress,
          city: '',
          state: '',
          countrySubdivisionId: '',
          postcode: vm.data.accountHolderRegisteredAddress.postcode,
          countryCode: vm.selectedCountry,
        };
      }

      if (
        vm.hasIndonesiaAndThailandRegionalExpansion &&
        vm.data.bankAddress.countryCode === 'SG' &&
        vm.data.bankAddress.postcode === ''
      ) {
        vm.data.bankAddress = {
          street: vm.data.bankAddress.street,
          city: '',
          state: '',
          countrySubdivisionId: '',
          postcode: vm.data.bankAddress.postcode,
          countryCode: vm.selectedCountry,
        };
      }

      resizeImage(vm.uploadedImage);
      vm.data.imageUrl = vm.uploadedImage;
      if (!vm.isEdit) {
        save(vm.data);
      } else if (vm.isEdit) {
        edit(vm.data);
      }
    }

    // Save new account owner.
    function save(input) {
      vm.saving += 1;
      accountOwnerSvc
        .createOrUpdateAccountOwner(input)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyCreated'));
          $state.go('accountOwners');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    // Save edit on existing account owner.
    function edit(input) {
      vm.saving += 1;
      accountOwnerSvc
        .createOrUpdateAccountOwner(input)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullyUpdated'));
          $state.go('accountOwners');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    // Toggle edit mode.
    function toggleEdit(flag) {
      vm.isEdit = flag;
    }

    // Cancel edit.
    function cancel() {
      if (!$scope.accountOwnerForm.$dirty) toggleCancel(false);
      else {
        abp.message.confirm(
          App.localize('UnsavedChangesConfirmation'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              toggleCancel(true);
            }
          }
        );
      }
    }

    // Cancel creation or edit.
    function toggleCancel(resetAccountOwner) {
      if (vm.isCreate) window.history.back();
      else {
        vm.toggleEdit(false);
        if (resetAccountOwner) {
          getAccountOwner(vm.requestParams.accountOwnerId);
        }
      }
    }
  }
})();
