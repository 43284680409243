import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('host.views.claims.inpatientClaims.adjustmentRequest', AdjustmentRequestController);

  AdjustmentRequestController.$inject = [
    '$window',
    '$scope',
    '$state',
    '$uibModal',
    '$stateParams',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.commonLookup',
    'moment',
    'appConstants',
  ];

  function AdjustmentRequestController(
    $window,
    $scope,
    $state,
    $modal,
    $stateParams,
    hostServiceRequestSvc,
    commonLookupSvc,
    moment,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;
    vm.letterNumber = $stateParams.letterNumber;
    vm.isHost = App.isHost();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.isEditRequestDetail = false;
    vm.isEditClaimDetail = false;
    vm.isClaim = true;
    vm.checkEditMode = checkEditMode;
    vm.isOnHold = false;
    vm.requestDetailsTrigger = 0;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.checkFormFilled = checkFormFilled;

    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openEscalationModal = openEscalationModal;
    vm.query = query;
    vm.preview = preview;
    vm.isTallied = true;
    vm.isFinalBillTallied = true;
    vm.isFormFilled = false;
    vm.requestAmount = null;
    vm.totalCoveredAmount = null;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.isInsuranceBenefitGroup = false;

    let delegateUsers = [];
    let recommendUsers = [];
    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0,
    };
    vm.roomAndBoardCharges = 0;

    vm.requestStatus = 'Pending approval';
    vm.isMaternity = false;

    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');

    function init() {
      vm.loading += 1;
      hostServiceRequestSvc
        .getAdjustmentRequestDetails({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.requestInfo = data;
          vm.isOnHold = data.request.isOnHold;
          vm.requestInfo.request.letterNumber = vm.letterNumber;
          vm.printUrl = data.printUrl;
          vm.hasUnresolvedQuery = data.request.hasUnresolvedQuery;
          vm.isMaternity = data.request.serviceType === 8;
          vm.isInsuranceBenefitGroup = data.patient.isInsuranceBenefitGroup;
          vm.currencyCode = data.request.currencyCode;
          vm.countryCode = data.request.countryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // watch for changes to selectedDoctorIds and update availableDischargingDoctor list accordingly

    $scope.$watch('vm.requestInfo.request.selectedDoctorIds', () => {
      if (vm.requestInfo) {
        // compare and add doctors from selected add-on doctors
        // that are not in the available discharging doctor list

        vm.loading += 1;
        hostServiceRequestSvc
          .updateSelectableDischargingDoctors({
            selectedAddOnDoctorIds: vm.requestInfo.request.selectedDoctorIds,
            availableDischargeDoctors: vm.requestInfo.request.availableDischargeDoctors,
          })
          .success((data) => {
            vm.requestInfo.request.availableDischargeDoctors = data;
            $scope.$broadcast('availableDischargeDoctorsChanged', data);
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    });

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger += 1;
    }

    function approve(remarks) {
      vm.loading += 1;
      const otherItems = [];
      let roomItems = [];
      let procedureItems = [];
      let doctorProcedureItems = [];
      let hospitalChargesItems = [];
      let emergencyOutpatientTreatments = [];
      let miscellaneousTreatments = [];

      const { request } = vm.requestInfo;

      _.each(
        vm.requestInfo.request.rooms.filter((x) => x.category),
        (x) => {
          _.each(x.ticketItems, (p) => {
            if (!p.roomChargeDateRangeModel) {
              p.roomChargeDateRangeModel = {
                startDate: p.startDate ? p.startDate.utc() : null,
                endDate: p.endDate ? p.endDate.utc() : null,
              };
            }
            p.startDate = p.roomChargeDateRangeModel.startDate;
            p.endDate = p.roomChargeDateRangeModel.endDate;

            if (typeof p.category !== 'undefined' && p.category !== null) {
              roomItems.push(p);
            }
          });
        }
      );

      if (vm.hasInsuranceEnhancementModule && !vm.isMaternity) {
        roomItems = formatTicketItems(vm.requestInfo.request.rooms, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.roomChargeDateRangeModel.startDate
            ? moment(x.roomChargeDateRangeModel.startDate).utc()
            : null,
          endDate: x.roomChargeDateRangeModel.endDate
            ? moment(x.roomChargeDateRangeModel.endDate).utc()
            : null,
          dailyCharges: x.dailyCharges,
          lengthOfStay: x.lengthOfStay,
        }));

        procedureItems = formatTicketItems(vm.requestInfo.request.procedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: vm.hasInsuranceEnhancementModule ? x.subCategory : x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
          totalVisit: 1,
          visit: x.visit,
        }));

        doctorProcedureItems = formatTicketItems(vm.requestInfo.request.doctorProcedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
        }));

        hospitalChargesItems = formatTicketItems(vm.requestInfo.request.hospitalCharges, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
        }));

        emergencyOutpatientTreatments = formatTicketItems(
          vm.requestInfo.request.emergencyOutpatientTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          })
        );

        miscellaneousTreatments = formatTicketItems(
          vm.requestInfo.request.miscellaneousTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          })
        );
      } else if (vm.hasSobEnhancement && !vm.isMaternity) {
        _.each(
          vm.requestInfo.request.procedures.filter((x) => x.category),
          (x) => {
            _.each(x.ticketItems, (p) => {
              p.startDate = p.visitDate ? moment(p.visitDate).utc() : null;
              p.endDate = p.visitDate ? moment(p.visitDate).utc() : null;

              // Total visit is always 1.

              if (!p.totalVisit || p.totalVisit !== 1) {
                p.totalVisit = 1;
              }

              if (
                (typeof p.doctorId !== 'undefined' && p.doctorId !== null) ||
                vm.hasTxtFileSupport
              ) {
                procedureItems.push(p);
              }
            });
          }
        );

        _.each(vm.requestInfo.request.doctorProcedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (typeof p.category !== 'undefined' && p.category !== null) {
              doctorProcedureItems.push(p);
            }
          });
        });

        _.each(
          vm.requestInfo.request.hospitalCharges.filter((x) => x.category),
          (x) => {
            _.each(x.ticketItems, (p) => {
              if (typeof p.category !== 'undefined' && p.category !== null) {
                hospitalChargesItems.push(p);
              }
            });
          }
        );
      } else {
        _.each(vm.requestInfo.request.procedures, (x) => {
          _.each(x.ticketItems, (p) => {
            if (!p.procedureChargeDateRangeModel) {
              p.procedureChargeDateRangeModel = {
                startDate: p.startDate ? p.startDate.utc() : null,
                endDate: p.endDate ? p.endDate.utc() : null,
              };
            }
            p.startDate = p.procedureChargeDateRangeModel.startDate;
            p.endDate = p.procedureChargeDateRangeModel.endDate;

            if (typeof p.category !== 'undefined' && p.category !== null) {
              procedureItems.push(p);
            }
          });
        });

        _.each(vm.requestInfo.request.others, (x) => {
          if (typeof x.category !== 'undefined' && x.category !== null) {
            otherItems.push(x);
          }
        });
      }

      request.rooms = roomItems;
      request.procedures = procedureItems;

      if ((vm.hasSobEnhancement || vm.hasInsuranceEnhancementModule) && !vm.isMaternity) {
        request.doctorProcedures = doctorProcedureItems;
        request.hospitalCharges = hospitalChargesItems;
        request.emergencyOutpatientTreatments = emergencyOutpatientTreatments;
        request.miscellaneousTreatments = miscellaneousTreatments;
      } else {
        request.others = otherItems;
      }

      // const input = $.extend(request, {
      //   remarks,
      //   currentAllowance: vm.employeeAllowance,
      // });

      const input = _.cloneDeep({ 
        ...request, 
        remarks,
        currentAllowance: vm.employeeAllowance,
      });

      input.diagnoses = _.map(request.diagnoses, (d) => ({
        id: d.id,
        code: _.isNumber(d.code) ? null : d.code,
        description: d.description,
        diagnosisDate: d.diagnosisDate,
      }));

      console.log('approve adjustment', input);
      
      hostServiceRequestSvc
        .approveAdjustmentRequest(input)
        .success(() => {
          abp.notify.success(App.localize('Approved'));
          navigateToListing();
        })
        .error((data) => {
          /* Error code 1 == Insufficient allowance */
          if (data.code === 1) {
            /* Insufficient employee balance for request amount */
            swal(
              {
                title: App.localize('InvalidAmount'),
                text: `${App.localize('InsufficientEmployeeBalanceNarrative')}\n
                ${App.localize('RefreshThePageAndEditTheAmount')}`,
                confirmButtonColor: '#1AB394',
                confirmButtonText: App.localize('RefreshNow'),
              },
              (isConfirm) => {
                if (isConfirm) {
                  $window.location.reload();
                }
              }
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function reject(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .rejectAdjustmentRequest({
          id: vm.requestInfo.request.id,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('Rejected'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function delegate(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .delegateRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.requestInfo.request.id,
          letterNumber: vm.letterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestDelegated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function recommend(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .recommendRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.requestInfo.request.id,
          letterNumber: vm.letterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestRecommended'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function query() {
      if (vm.hasUnresolvedQuery) {
        $state.go('inpatientGuaranteeLettersDetails', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
          tab: 3,
        });
      } else {
        $state.go('createQuery', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
        });
      }
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function checkFormFilled(data) {
      vm.isFormFilled = data;
    }

    function openApprovalModal() {
      $scope.$broadcast('validateProcessingInput');

      if (vm.isFormFilled) {
        if (!vm.isTallied) {
          vm.isFinalBillTallied = false;
          abp.message.error(App.localize('FinalBillNotTallied'));
          return;
        }
        vm.isFinalBillTallied = true;

        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
              controller: 'common.views.modals.remarksModal as vm',
              backdrop: 'static',
              resolve: {
                title: () => App.localize('ApprovalConfirmation'),
                existingRemarks: () => null,
                remarksMaxLength: () => appConstants.serviceRequestMaxLength,
              },
            })
            .result.then((remarks) => {
              approve(remarks);
            });
        } else {
          promptEditModeWarning();
        }
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
      }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title: () => App.localize('RejectionConfirmation'),
              existingRemarks: () => null,
              remarksMaxLength: () => appConstants.serviceRequestMaxLength,
            },
          })
          .result.then((remarks) => {
            reject(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users: () => delegateUsers,
                title: () => App.localize('DelegationConfirmation'),
                message: () => App.localize('DelegationWarningMessage'),
              },
            })
            .result.then((result) => {
              delegate(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users: () => recommendUsers,
                title: () => App.localize('RecommendationConfirmation'),
                message: () => App.localize('RecommendationWarningMessage'),
              },
            })
            .result.then((result) => {
              recommend(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('host.inpatientGuaranteeLetters');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestClaimProcessor()
        .success((data) => {
          // Filter away current user
          delegateUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestOverridingApprover()
        .success((data) => {
          recommendUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title: () => App.localize('EscalationConfirmation'),
              existingRemarks: () => vm.requestInfo.request.escalationRemark,
              remarksMaxLength: () => appConstants.serviceRequestMaxLength,
            },
          })
          .result.then((remarks) => {
            escalate(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function escalate(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .escalateGuaranteeLetterRequest({
          id: vm.requestInfo.request.id,
          isResolve: true,
          escalationRemark: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('GlEscalated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function formatTicketItems(treatments, format) {
      const items = [];

      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (!y.mainTreatment && !y.category && !y.doctorType) return;

          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });

      return items;
    }

    init();
  }
})();
