(function () {
    angular.module('app').component('hostInpatientGuaranteeLettersCommonAdmissionDetailsComponent', {
        templateUrl: require('/App/host/views/inpatientGuaranteeLetters/common/admissionDetails/admissionDetails.component.html'),
        controller: ['$stateParams', 'abp.services.app.inpatientGuaranteeLetter', 'abp.services.app.hostServiceRequest', 'moment', controller],
        controllerAs: 'vm',
        bindings: {
            guaranteeLetterNumber: '<',
            isPrintButtonHidden: '<',
            currencyCode: '<',
        }
    });

    function controller($stateParams, inpatientGuaranteeLetterSvc, hostServiceRequestSvc, moment) {
        var vm = this;
        vm.loading = 0;
        vm.admission = null;
        vm.diagnosis = null;
        vm.documents = []
        vm.admissionTypes = [];
        vm.viewMore = false;
        vm.printing = null;
        vm.finalBillAmount = null;
        vm.interimBillAmount = null;

        vm.$onChanges = onChanges;
        vm.getAdmissionType = getAdmissionType;
        vm.getDateTimeString = getDateTimeString;

        function onChanges(changes) {
            if (changes.guaranteeLetterNumber && changes.guaranteeLetterNumber.currentValue) {
                getAdmissionDetails();
                getGLPrintingDetails();
                getfinalBillAmount();
                getInterimBillAmount();
            }
        }

        function getGLPrintingDetails() {
            vm.loading++
            inpatientGuaranteeLetterSvc.getServiceRequestDetailsForTimelineButtons({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.printing = {
                    printGlEnabled: data.printGlEnabled,
                    printGlUrl: data.printGlUrl,
                }
            }).finally(function (e) {
                vm.loading--
            });
        }

        function getfinalBillAmount() {
            vm.loading++
            hostServiceRequestSvc.getFinalBillAmountForAdmissionDetails({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.finalBillAmount = data.finalBillAmount;
            }).finally(function (e) {
                vm.loading--;
            });
        }

        function getInterimBillAmount() {
            vm.loading++
            hostServiceRequestSvc.getInterimBillForAdmissionDetails({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.interimBillAmount = data.interimBillAmount;
            }).finally(function (e) {
                vm.loading--;
            })
        }
        function getAdmissionDetails() {
            vm.loading++;
            inpatientGuaranteeLetterSvc.getProcessAdmissionDetails({
                id: vm.guaranteeLetterNumber
            }).success(function (data) {
                vm.admission = {
                    hospitalName: data.hospitalName,
                    hospitalContactNumber: data.hospitalContactNumber,
                    guaranteeLetterNumber: data.guaranteeLetterNumber,
                    admissionNumber: data.admissionNumber,
                    scheduledAdmissionTime: data.scheduledAdmissionTime,
                    checkedInTime: data.checkedInTime,
                    admissionType: data.admissionType,
                    doctors: _.map(data.guaranteeLetterDoctors, 'name').join(', '),
                    estimatedCost: data.estimatedCost,
                    estimatedDischargeDate: data.estimatedDischargeDate,
                    latestRequestType: data.latestRequestType
                };

                vm.documents = _.map(data.supportingDocuments, function (d) {
                    return {
                        externalObjectId: d.externalObjectId,
                        url: d.url,
                        name: d.name,
                        state: d.state,
                        uploader: d.uploader + ' ' + getDateTimeString(d.uploadedTime),
                        applied: d.isApplied
                    };
                });

                vm.diagnosis = {
                    selected: _.map(data.provisionalDiagnosis, function (p) {
                        return {
                            code: p.code,
                            description: p.description,
                            displayDate: p.displayDiagnosisDate,
                            date: p.diagnosisDate
                        };
                    }),
                    icd: _.map(data.provisionalDiagnosis, function (d) {
                        return {
                            code: d.code,
                            description: '(' + d.code + ') ' + d.description
                        };
                    })
                };

                vm.admissionTypes = data.admissionTypes;
            }).finally(function (e) {
                vm.loading--
            });
        }

        function getAdmissionType() {
            if (vm.admission && vm.admission.admissionType) {
                var admissionType = _.find(vm.admissionTypes, function (t) {
                    return t.id === vm.admission.admissionType;
                });

                return admissionType.name;
            }
        }

        function getDateTimeString(date, timeZoneId = $stateParams.timeZoneId) {
            return App.getDateTimeString(date, timeZoneId);
        }
    }
})();
