(() => {
  angular
    .module('app')
    .controller('host.views.inpatientGuaranteeLetters.page', InpatientGuaranteeLettersPageController);

  InpatientGuaranteeLettersPageController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$uibModal',
    '$timeout',
    'abp.services.app.inpatientGuaranteeLetter',
    'abp.services.app.commonLookup',
    'abp.services.app.hostServiceRequest',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.ServiceRequests.ServiceRequestQueryStatus',
    'moment',
    'appConstants',
  ];

  function InpatientGuaranteeLettersPageController(
    $scope,
    $stateParams,
    $state,
    $modal,
    $timeout,
    inpatientGuaranteeLetterSvc,
    commonLookupSvc,
    hostServiceRequestSvc,
    enumServiceRequestStatus,
    enumGuaranteeLetterStatus,
    enumServiceRequestQueryStatus,
    moment,
    appConstants
  ) {
    const vm = this;
    vm.loading = 0;
    vm.exporting = 0;
    vm.isHost = App.isHost();
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.hasInsuranceModule = abp.setting.getBoolean('Hms.Feature.InsuranceModule');
    vm.advancedFiltersAreShown = false;
    vm.panelLocations = [];

    vm.enums = {};
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
    vm.enums.serviceRequestQueryStatus = enumServiceRequestQueryStatus;

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    vm.requestParams = {
      skipCount: $stateParams.skipCount || 0,
      maxResultCount: $stateParams.maxResultCount || appConstants.grid.defaultPageSize,
      sorting: $stateParams.sorting,
      letterNumber: $stateParams.letterNumber,
      claimNumber: $stateParams.claimNumber,
      ticketNumber: $stateParams.ticketNumber,
      status: $stateParams.status,
      panelLocationId: $stateParams.panelLocationId,
      processorId: $stateParams.processorId,
      patient: $stateParams.patient,
      subsidiary: $stateParams.subsidiary,
      latestRequestType: $stateParams.latestRequestType,
      latestRequestStatus: $stateParams.latestRequestStatus,
      queryStatus: $stateParams.queryStatus,
      serviceType: $stateParams.serviceType,
      insurerId: $stateParams.insurerId,
      isCoveredByInsurance: $stateParams.isCoveredByInsurance,
      masterPolicyNumber: $stateParams.masterPolicyNumber,
      subsidiaryName: $stateParams.subsidiaryName,
    };

    vm.approvalDateRangeOptions = App.createDateRangePickerOptions();
    vm.approvalDateRangeModel = {
      startDate: $stateParams.approvalStartDate ? moment($stateParams.approvalStartDate) : null,
      endDate: $stateParams.approvalEndDate ? moment($stateParams.approvalEndDate) : null,
    };

    vm.scheduledDateRangeOptions = App.createDateRangePickerOptions();
    vm.scheduledDateRangeModel = {
      startDate: $stateParams.scheduledAdmissionStartDate
        ? moment($stateParams.scheduledAdmissionStartDate)
        : null,
      endDate: $stateParams.scheduledAdmissionEndDate
        ? moment($stateParams.scheduledAdmissionEndDate)
        : null,
    };

    vm.checkedInDateRangeOptions = App.createDateRangePickerOptions();
    vm.checkedInDateRangeModel = {
      startDate: $stateParams.checkedInStartDate ? moment($stateParams.checkedInStartDate) : null,
      endDate: $stateParams.checkedInEndDate ? moment($stateParams.checkedInEndDate) : null,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: appConstants.grid.defaultPageSizes,
      paginationPageSize: appConstants.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      resizable: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
          vm.getGuaranteeLetters();
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, vm.getGuaranteeLetters)
        );
      },
      data: [],
    };

    vm.showUpdateIssueModal = showUpdateIssueModal;
    vm.updateIssue = updateIssue;
    vm.getGuaranteeLetters = getGuaranteeLetters;
    vm.exportToExcel = exportToExcel;
    vm.processRequest = processRequest;
    vm.showEscalateModal = showEscalateModal;
    vm.insurerManager = insurerManager;
    vm.getDateTimeString = getDateTimeString;

    init();

    function init() {
      // Get filter options
      getHospitalLocations();
      getListingFilters();
      getServiceRequestProcessors();
      if (vm.hasInsuranceModule) getInsurers();
      $timeout(() => {
        vm.gridOptions.paginationCurrentPage = Math.floor(
          vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
        );
      });
    }

    /* Functions */

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: 'Actions',
          displayName: 'Actions',
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'viewTemplate',
          allowCellFocus: false,
        },
        {
          displayName: App.localize('Patient'),
          enableSorting: false,
          field: 'patientName',
          minWidth: 120,
          cellTemplate: 'patientTemplate',
        },
        {
          displayName: App.localize('GlStatus'),
          field: 'status',
          width: 120,
          minWidth: 120,
          cellTemplate: 'statusTemplate',
        },
        {
          displayName: App.localize('LatestRequestType'),
          field: 'latestRequestDescription',
          minWidth: 100,
          cellTemplate: 'latestRequestType',
          width: 220,
        },
        {
          displayName: App.localize('QueryStatus'),
          field: 'latestQueryStatus',
          minWidth: 100,
          cellTemplate: 'queryStatusTemplate',
        },
        {
          displayName: App.localize('Assignee'),
          enableSorting: false,
          field: 'processorName',
          minWidth: 100,
          cellTemplate: 'processorTemplate',
        },
        {
          displayName: App.localize('ProcessedTime'),
          enableSorting: false,
          field: 'processedTime',
          minWidth: 100,
          cellTemplate: 'processedTimeTemplate',
        },
        {
          displayName: App.localize('RequestAgeing'),
          enableSorting: false,
          field: 'aging',
          minWidth: 100,
          cellTemplate: 'agingTemplate',
        },
        {
          displayName: App.localize('PanelLocation'),
          enableSorting: false,
          field: 'panelLocationName',
          minWidth: 120,
        },
        {
          displayName: App.localize('Subsidiary'),
          enableSorting: false,
          field: 'patientSubsidiaryName',
          minWidth: 120,
        },
        {
          displayName: App.localize('GuaranteeLetterNumber'),
          field: 'letterNumber',
          minWidth: 100,
          width: 180,
        },
        {
          displayName: App.localize('GuaranteeLetterType'),
          field: 'serviceType',
          minWidth: 50,
          cellTemplate: 'serviceTypeTemplate',
        },
        {
          displayName: App.localize('ClaimNumber'),
          field: 'claimNumber',
          minWidth: 100,
          width: 150,
          cellTemplate: 'claimNumberTemplate',
        },
        {
          displayName: App.localize('TicketNumber'),
          field: 'ticketNumber',
          minWidth: 100,
          width: 140,
          cellTemplate: 'ticketNumberTemplate',
        },
      ];

      if (vm.hasInsuranceModule) {
        columnDefs.push(
          {
            displayName: App.localize('Insurer'),
            field: 'insurerName',
            minWidth: 100,
            width: 140,
            cellTemplate: 'insurerTemplate',
          },
          {
            displayName: App.localize('PolicyNo'),
            field: 'masterPolicyNumber',
            minWidth: 100,
            width: 140,
            cellTemplate: 'policyNoTemplate',
          }
        );
      }
      return columnDefs;
    }

    function getHospitalLocations() {
      vm.loading += 1;
      inpatientGuaranteeLetterSvc
        .findHospitalLocations()
        .success((data) => {
          vm.panelLocations = data.items;
        })
        .finally(() => {
          if (!vm.panelLocations.length) {
            vm.requestParams.panelLocationId = null;
          }
          vm.loading -= 1;
        });
    }

    function showUpdateIssueModal(guaranteeLetterNumber) {
      $modal
        .open({
          templateUrl: require('./updateIssueNumber/updateIssueNumber.modal.html'),
          controller: 'host.views.inpatientGuaranteeLetters.updateIssueNumber.modal as vm',
          backdrop: 'static',
        })
        .result.then((issueNumber) => {
          if (issueNumber) {
            updateIssue(guaranteeLetterNumber, issueNumber);
          }
        });
    }

    function updateIssue(guaranteeletterNumber, issueNumber) {
      const input = {
        letterNumber: guaranteeletterNumber,
        issueNumber,
      };

      vm.loading += 1;
      inpatientGuaranteeLetterSvc
        .updateIssueNumber(input)
        .success(() => {
          abp.notify.success(App.localize('Success'));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getGuaranteeLetters() {
      const input = getRequestInput();

      vm.loading += 1;
      inpatientGuaranteeLetterSvc
        .getInpatientGuaranteeLetters(input)
        .success((data) => {
          vm.gridOptions.data = data.items;
          vm.gridOptions.totalItems = data.totalCount;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getServiceRequestProcessors() {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestProcessors()
        .success((data) => {
          vm.serviceRequestProcessors = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getListingFilters() {
      inpatientGuaranteeLetterSvc.getListingFilters().success((data) => {
        vm.admissionTypes = data.admissionTypes;
        vm.letterStatuses = data.letterStatuses;
        vm.serviceRequestStatuses = data.requestStatuses;
        vm.serviceRequestTypes = data.requestTypes;
        vm.queryStatuses = data.queryStatuses;
        vm.serviceTypes = data.serviceTypes;
      });
    }

    function getInsurers() {
      vm.loading += 1;
      commonLookupSvc
        .getInsurers()
        .success((data) => {
          vm.insurers = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function exportToExcel() {
      const input = getRequestInput();

      vm.exporting += 1;
      inpatientGuaranteeLetterSvc
        .getInpatientGuaranteeLettersToExcel(input)
        .success((data) => {
          App.downloadTempFile(data);
        })
        .finally(() => {
          abp.notify.success(App.localize('Success'));
          vm.exporting -= 1;
        });
    }

    function getRequestInput() {
      const input = $.extend({}, vm.requestParams);

      input.approvalStartDate = vm.approvalDateRangeModel.startDate;
      input.approvalEndDate = vm.approvalDateRangeModel.endDate;

      input.scheduledAdmissionStartDate = vm.scheduledDateRangeModel.startDate;
      input.scheduledAdmissionEndDate = vm.scheduledDateRangeModel.endDate;

      input.checkedInStartDate = vm.checkedInDateRangeModel.startDate;
      input.checkedInEndDate = vm.checkedInDateRangeModel.endDate;

      registerStateParams();
      return input;
    }

    function registerStateParams() {
      const approvalStartDate = vm.approvalDateRangeModel.startDate
        ? vm.approvalDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const approvalEndDate = vm.approvalDateRangeModel.endDate
        ? vm.approvalDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      const scheduledAdmissionStartDate = vm.scheduledDateRangeModel.startDate
        ? vm.scheduledDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const scheduledAdmissionEndDate = vm.scheduledDateRangeModel.endDate
        ? vm.scheduledDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      const checkedInStartDate = vm.checkedInDateRangeModel.startDate
        ? vm.checkedInDateRangeModel.startDate.format('YYYY-MM-DD')
        : null;
      const checkedInEndDate = vm.checkedInDateRangeModel.endDate
        ? vm.checkedInDateRangeModel.endDate.format('YYYY-MM-DD')
        : null;

      $state.transitionTo(
        $state.current,
        {
          sorting: vm.requestParams.sorting,
          skipCount: vm.requestParams.skipCount,
          maxResultCount: vm.requestParams.maxResultCount,
          letterNumber: vm.requestParams.letterNumber,
          claimNumber: vm.requestParams.claimNumber,
          ticketNumber: vm.requestParams.ticketNumber,
          status: vm.requestParams.status,
          panelLocation: vm.requestParams.panelLocationId,
          patient: vm.requestParams.patient,
          subsidiary: vm.requestParams.subsidiary,
          latestRequestType: vm.requestParams.latestRequestType,
          latestRequestStatus: vm.requestParams.latestRequestStatus,
          processorId: vm.requestParams.processorId,
          queryStatus: vm.requestParams.queryStatus,
          insurerId: vm.requestParams.insurerId,
          approvalStartDate,
          approvalEndDate,
          scheduledAdmissionStartDate,
          scheduledAdmissionEndDate,
          checkedInStartDate,
          checkedInEndDate,
          isCoveredByInsurance: vm.requestParams.isCoveredByInsurance,
          masterPolicyNumber: vm.requestParams.masterPolicyNumber,
        },
        {
          notify: false,
        }
      );
    }

    function processRequest(guaranteeLetter) {
      if (guaranteeLetter.latestRequestType === 'Initial') {
        $state.go('host.iglRequest', {
          letterNumber: guaranteeLetter.letterNumber,
          requestId: guaranteeLetter.latestRequestId,
          memberId: guaranteeLetter.patientMemberId,
        });
      } else if (guaranteeLetter.latestRequestType === 'CheckIn') {
        $state.go('host.checkInRequest', {
          letterNumber: guaranteeLetter.letterNumber,
          requestId: guaranteeLetter.latestRequestId,
          memberId: guaranteeLetter.patientMemberId,
        });
      } else if (guaranteeLetter.latestRequestType === 'TopUpAddOn') {
        $state.go('host.topupAddonRequest', {
          letterNumber: guaranteeLetter.letterNumber,
          requestId: guaranteeLetter.latestRequestId,
          memberId: guaranteeLetter.patientMemberId,
        });
      }
    }

    function insurerManager() {
      if (vm.requestParams.isCoveredByInsurance !== 'true') {
        vm.requestParams.insurerId = null;
      }
    }

    function showEscalateModal(id) {
      vm.loading += 1;
      hostServiceRequestSvc
        .getEscalationRemarks({ id })
        .success((data) => {
          openEscalationRemarkModal(id, data);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function openEscalationRemarkModal(requestId, currentRemarks) {
      $modal
        .open({
          templateUrl: require('./updateEscalateRemarks/updateEscalateRemarks.modal.html'),
          controller: 'host.views.inpatientGuaranteeLetters.updateEscalateRemarks.modal as vm',
          backdrop: 'static',
          resolve: {
            remarks() {
              return currentRemarks;
            },
          },
        })
        .result.then((data) => {
          hostServiceRequestSvc
            .escalateGuaranteeLetterRequest({
              id: requestId,
              isResolve: data.isResolve,
              escalationRemark: data.remark,
            })
            .success(() => {
              vm.getGuaranteeLetters();
            });
        });
    }

    function getDateTimeString(date, timeZoneId) {
        return App.getDateTimeString(date, timeZoneId);
    }

    /* End of functions */
  }
})();
