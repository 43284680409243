import _ from 'lodash';

(() => {
  angular.module('app').component('editIrbmMyInvoisSettings', {
    templateUrl: require('./widget.html'),
    controller: EditIrbmMyInvoisSettingsController,
    controllerAs: 'vm',
    bindings: {
      data: '<',
      isClinic: '<',
      countryCode: '<',
      isCreate: '<',
    },
  });

  EditIrbmMyInvoisSettingsController.$inject = [
    'abp.services.finance.commonLookup',
    'abp.services.finance.malaysiaTaxIdentifier',
    'abp.services.app.malaysiaTaxIdentifier',
    '$scope',
  ];

  function EditIrbmMyInvoisSettingsController(
    commonLookupSvc,
    financeMalaysiaTaxIdentifierSvc,
    portalMalaysiaTaxIdentifierSvc,
    $scope
  ) {
    const vm = this;
    vm.container = $scope.$parent.vm;
    vm.loading = 0;
    vm.generalTinCollectionNarrative = null;
    vm.corporateTinCollectionNarrative = App.localize('CorporateTinCollectionNarrative');
    vm.taxIdentificationNumberTooltip = App.localize('TaxIdentificationNumberTooltip');

    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = validateForm;
    vm.$onInit = init;

    function init() {
      if (vm.isCreate) {
        vm.container.editIrbmMyInvoisSettingsWidget = this;
      } else {
        vm.container.addWidget(this);
      }

      const watch = $scope.$watch(
        'vm.data',
        (newValue) => {
          if (newValue) {
            vm.generalTinCollectionNarrative = App.localize(
              'GeneralTinCollectionNarrative',
              vm.data.subTenants.length
            );
            getSettings();
            watch(); // deregister the watch
          }
        },
        true
      ); // watch for changes to vm.data
    }

    function getSettings() {
      // HM-9446 Temporary disable tenant side feature flag retrieval
      // until permission issue resolved.
      if (App.isCorporate() || App.isClinic()) {
        vm.hasTaxIdentifierCollection = true;
        mapTaxIdentifierInformation();
      } else {
        vm.loading += 1;
        commonLookupSvc
          .getCommonSettings()
          .success((data) => {
            vm.hasTaxIdentifierCollection = data.taxIdentifierCollection;
            mapTaxIdentifierInformation();
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function mapTaxIdentifierInformation() {
      if (vm.hasTaxIdentifierCollection) {
        let primarySubsidiaryTaxIdentifiers = null;

        if (vm.data.isSubsidiaryBillAccountMode != null && !vm.data.isSubsidiaryBillAccountMode)
          primarySubsidiaryTaxIdentifiers = _.find(
            vm.data.subTenants,
            (subTenant) => subTenant.isPrimary
          );

        vm.taxIdentifiers = _.cloneDeep(
          primarySubsidiaryTaxIdentifiers ? [primarySubsidiaryTaxIdentifiers] : vm.data.subTenants
        );
      }
    }

    function getInput() {
      if (vm.data.isSubsidiaryBillAccountMode != null && !vm.data.isSubsidiaryBillAccountMode) {
        const primarySubsidiaryTaxInfo = vm.taxIdentifiers[0];

        _.forEach(vm.data.subTenants, (subTenant) => {
          subTenant.taxIdentificationNumber = primarySubsidiaryTaxInfo.taxIdentificationNumber;
          subTenant.businessRegistrationNumber =
            primarySubsidiaryTaxInfo.businessRegistrationNumber;
          subTenant.sstRegistrationNumber = primarySubsidiaryTaxInfo.sstRegistrationNumber;
          subTenant.supportContactName = primarySubsidiaryTaxInfo.supportContactName;
          subTenant.supportContactEmail = primarySubsidiaryTaxInfo.supportContactEmail;
          subTenant.supportContactNumber = primarySubsidiaryTaxInfo.supportContactNumber;
        });

        return vm.data.subTenants;
      }

      return vm.taxIdentifiers;
    }

    function getPayload(settings) {
      settings.malaysiaTaxIdentifiers = {
        subTenants: getInput(),
      };
    }

    function validateForm() {
      const input = _.filter(
        getInput(),
        (d) =>
          d.taxIdentificationNumber !== null &&
          d.taxIdentificationNumber !== undefined &&
          d.taxIdentificationNumber !== ''
      );

      if (!vm.hasTaxIdentifierCollection || vm.countryCode !== 'MY' || input.length === 0)
        return true;

      const { irbmMyInvoisSettingsEditForm } = $scope.vm;
      if (irbmMyInvoisSettingsEditForm.$invalid) {
        abp.notify.error(App.localize('TaxIdentifiersInvalidFormInputError'));
        return true;
      }

      vm.loading += 1;
      financeMalaysiaTaxIdentifierSvc
        .bulkValidateTin({
          taxIdentifiers: input,
        })
        .success((message) => {
          if (message.output) abp.message.error(message.output);
          else {
            portalMalaysiaTaxIdentifierSvc
              .createUpdateTaxIdentifier({ subTenants: input })
              .success(() => {
                abp.notify.info(App.localize('TaxIdentifiersSuccessfullySaved'));
              });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
      return true;
    }
  }
})();
