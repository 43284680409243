(() => {
  angular.module('app').controller('host.views.debugTools.index', DebugToolsController);

  DebugToolsController.$inject = [
    '$scope',
    '$window',
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.debugTool',
  ];

  function DebugToolsController($scope, $window, $state, $stateParams, $modal, debugToolSvc) {
    const vm = this;
    vm.saving = 0;
    vm.groupByPerItem = 3;

    vm.goToClearCacheTool = goToClearCacheTool;
    vm.goToHangfireDashboard = goToHangfireDashboard;
    vm.performTenantSnapshot = performTenantSnapshot;
    vm.onClickTenantSnapshot = onClickTenantSnapshot;
    vm.onClickTenantSnapshotWithEmail = onClickTenantSnapshotWithEmail;
    vm.performTenantFeatureSnapshot = performTenantFeatureSnapshot;
    vm.performEmployeeSnapshot = performEmployeeSnapshot;
    vm.calculateGpPriceRating = calculateGpPriceRating;
    vm.createMissingTrackers = createMissingTrackers;
    vm.createInitialSubsidiaryDataConsents = createInitialSubsidiaryDataConsents;
    vm.syncZendeskOrganizations = syncZendeskOrganizations;
    vm.syncOneSignalDataTagsAsync = syncOneSignalDataTagsAsync;
    vm.resetOneSignalDataTagsAsync = resetOneSignalDataTagsAsync;
    vm.patchPanelLocationOperationHour = patchPanelLocationOperationHour;
    vm.patchSubsidiaryVirtualAccountNumber = patchSubsidiaryVirtualAccountNumber;
    vm.patchDuplicatedSettingRecords = patchDuplicatedSettingRecords;
    vm.patchPanelAccessPolicyRules = patchPanelAccessPolicyRules;
    vm.patchPanelServicesToOrganizationBenefitGroup = patchPanelServicesToOrganizationBenefitGroup;
    vm.patchHospitalizationServiceInpatientTicketItems =
      patchHospitalizationServiceInpatientTicketItems;
    vm.subsidiarySnapshotsInitialization = subsidiarySnapshotsInitialization;
    vm.patchSingaporeCities = patchSingaporeCities;
    vm.updateOpaBundleAsync = updateOpaBundleAsync;
    vm.updateZendeskOrganizationExternalIds = updateZendeskOrganizationExternalIds;
    vm.tagZendeskOrganizations = tagZendeskOrganizations;
    vm.createZendeskOrganizationsByCountry = createZendeskOrganizationsByCountry;
    vm.retriggerMemberClaimCreateUpdateForMissingMemberClaimsInfo =
      retriggerMemberClaimCreateUpdateForMissingMemberClaimsInfo;
    vm.retriggerTicketBillingCreationForMissingPatientTicketBillingInfo =
      retriggerTicketBillingCreationForMissingPatientTicketBillingInfo;
    vm.updateAdminRolePermissions = updateAdminRolePermissions;
    vm.regenerateServiceFeatureHeadcountFile = regenerateServiceFeatureHeadcountFile;
    vm.patchNewEmployeeMovement = patchNewEmployeeMovement;
    vm.regenerateSubscriptionReports = regenerateSubscriptionReports;

    const debugTools = [
      {
        onClick: vm.onClickTenantSnapshot,
        description: 'Snapshot Tenant',
      },
      {
        onClick: vm.onClickTenantSnapshotWithEmail,
        description: 'Snapshot Tenant with Email',
      },
      {
        onClick: vm.performEmployeeSnapshot,
        description: 'Snapshot Employee',
      },
      {
        onClick: vm.performTenantFeatureSnapshot,
        description: 'Snapshot Tenant feature',
      },
      {
        onClick: vm.createInitialSubsidiaryDataConsents,
        description: 'Create initial subsidiary data consents',
      },
      {
        onClick: vm.goToClearCacheTool,
        description: App.localize('ClearCache'),
        redirection: true,
      },
      {
        onClick: vm.goToHangfireDashboard,
        description: App.localize('HangfireDashboard'),
        redirection: true,
      },
      {
        onClick: vm.calculateGpPriceRating,
        description: 'Calculate GP Price Rating',
      },
      {
        onClick: vm.syncZendeskOrganizations,
        description: 'Sync Zendesk Organisations',
      },
      {
        onClick: vm.subsidiarySnapshotsInitialization,
        description: 'Initialise subsidiary snapshots',
      },
      {
        onClick: vm.updateZendeskOrganizationExternalIds,
        description: 'Update Zendesk organisation External Ids',
      },
      {
        onClick: vm.tagZendeskOrganizations,
        description: 'Tag Zendesk Organisations',
      },
      {
        onClick: vm.createZendeskOrganizationsByCountry,
        description: 'Create Zendesk Organisations by Country',
      },
      {
        onClick: vm.regenerateServiceFeatureHeadcountFile,
        description: 'Regenerate service feature headcount file',
      },
    ];

    const oneSignalTools = [
      {
        onClick: vm.syncOneSignalDataTagsAsync,
        description: 'Update OneSignal data tags',
      },
      {
        onClick: vm.resetOneSignalDataTagsAsync,
        description: 'Reset OneSignal data tags',
      },
    ];

    const patchTools = [
      {
        onClick: vm.patchPanelLocationOperationHour,
        description: 'Patch Panel Location Operation Hour',
      },
      {
        onClick: vm.patchSubsidiaryVirtualAccountNumber,
        description: 'Patch subsidiary virtual account number',
      },
      {
        onClick: vm.patchDuplicatedSettingRecords,
        description: 'Remove or clear duplicated AbpSettings',
      },
      {
        onClick: vm.patchPanelAccessPolicyRules,
        description: 'Patch panel access policy rules',
      },
      {
        onClick: vm.patchPanelServicesToOrganizationBenefitGroup,
        description: 'Patch panel services to organisation benefit groups',
      },
      {
        onClick: vm.patchHospitalizationServiceInpatientTicketItems,
        description: 'Patch hospitalisation service inpatient ticket items',
      },
      {
        onClick: vm.patchSingaporeCities,
        description: 'Patch Singapore Cities',
      },
      {
        onClick: vm.retriggerMemberClaimCreateUpdateForMissingMemberClaimsInfo,
        description: 'Re-trigger member claim create update',
      },
      {
        onClick: vm.retriggerTicketBillingCreationForMissingPatientTicketBillingInfo,
        description: 'Re-trigger ticket billing creation',
      },
      {
        onClick: vm.patchNewEmployeeMovement,
        description: 'Patch new employee movement',
      },
      {
        onClick: vm.regenerateSubscriptionReports,
        description: 'Regenerate subscription charges report',
      },
    ];

    const opaTools = [
      {
        onClick: vm.updateOpaBundleAsync,
        description: 'Update OPA bundle',
      },
    ];

    const adminRolePermissionsTools = [
      {
        onClick: vm.updateAdminRolePermissions,
        description: 'Update Admin role permissions',
      },
    ];

    vm.selectionGroups = [
      {
        tools: groupBy(debugTools, vm.groupByPerItem),
      },
      {
        description: 'OneSignal',
        tools: groupBy(oneSignalTools, vm.groupByPerItem),
      },
      {
        description: 'Patch tools',
        tools: groupBy(patchTools, vm.groupByPerItem),
      },
      {
        description: 'Open Policy Agent (OPA)',
        tools: groupBy(opaTools, vm.groupByPerItem),
      },
      {
        description: 'Admin Role Permissions',
        tools: groupBy(adminRolePermissionsTools, vm.groupByPerItem),
      },
    ];

    init();

    function init() {
      $scope.$on('$viewContentLoaded', () => {
        App.initAjax();
      });

      $scope.openUrlInNewTab = (url) => {
        $window.open(url, '_blank');
      };
    }

    function groupBy(arr, n) {
      const group = [];
      for (let i = 0, end = arr.length / n; i < end; i += 1)
        group.push(arr.slice(i * n, (i + 1) * n));
      return group;
    }

    function goToClearCacheTool() {
      $state.go('host.clearCache');
    }

    function goToHangfireDashboard() {
      $scope.openUrlInNewTab('/hangfire');
    }

    function performTenantSnapshot(sendEmail) {
      if (vm.saving) return;

      const msg = sendEmail
        ? 'Confirm initiate snapshot tenant with email?'
        : 'Confirm initiate snapshot tenant?';
      abp.message.confirm(msg, App.localize('AreYouSure'), (result) => {
        if (result && !vm.saving) {
          vm.saving += 1;
          debugToolSvc
            .performTenantSnapshot({
              sendSupportPlanChangesEmail: !!sendEmail,
            })
            .success(() => {
              abp.notify.info('Snapshot tenant successful');
            })
            .finally(() => {
              vm.saving -= 1;
            });
        }
      });
    }

    function onClickTenantSnapshot() {
      vm.performTenantSnapshot(0);
    }

    function onClickTenantSnapshotWithEmail() {
      vm.performTenantSnapshot(1);
    }

    function performTenantFeatureSnapshot() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm initiate snapshot tenant feature?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .performTenantFeatureSnapshot()
              .success(() => {
                abp.notify.info('Snapshot tenant feature successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function performEmployeeSnapshot() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm initiate snapshot employee',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .performEmployeeSnapshot()
              .success(() => {
                abp.notify.info('Snapshot employee successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function calculateGpPriceRating() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm initiate GP price rating calculation',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .calculateGPPriceRating()
              .success(() => {
                abp.notify.info('Price rating calculated successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function createMissingTrackers() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm create missing Year 2020 commission trackers',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .createMissingYear2020CommissionTrackers()
              .success(() => {
                abp.notify.info('Create missing Year 2020 commission trackers successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function createInitialSubsidiaryDataConsents() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm initiate initial subsidiary data consent',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .createInitialSubsidiaryDataConsents()
              .success(() => {
                abp.notify.info('Create initial subsidiary data consents successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function syncZendeskOrganizations() {
      vm.saving += 1;
      debugToolSvc
        .syncZendeskOrganizationsAsync()
        .success(() => {
          abp.notify.info('Organization sync successful.');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function syncOneSignalDataTagsAsync() {
      vm.saving += 1;
      debugToolSvc
        .syncOneSignalDataTagsAsync()
        .success(() => {
          abp.notify.info('Update data tags of all employees successful.');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function resetOneSignalDataTagsAsync() {
      const modal = $modal.open({
        templateUrl: require('./oneSignalDataTagsRemoval/oneSignalDataTagsRemoval.modal.html'),
        controller:
          'host.views.debugTools.oneSignalDataTagsRemoval.oneSignalDataTagsRemoval.modal as vm',
        backdrop: 'static',
      });
      modal.result.then(() => {
        // EMPTY
      });
    }

    function patchPanelLocationOperationHour() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch panel location operation hour',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .panelLocationOperationHourPatch()
              .success(() => {
                abp.notify.info('Patching successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchSubsidiaryVirtualAccountNumber() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch subsidiary virtual account number?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchSubsidiaryVirtualAccountNumber()
              .success(() => {
                abp.notify.info('Patching successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchDuplicatedSettingRecords() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch settings? This patch will remove duplicated settings',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchDuplicatedSettingRecords()
              .success(() => {
                abp.notify.info('Patching jobs enqueued');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchPanelAccessPolicyRules() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch panel access policy rules?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchPanelAccessRules()
              .success(() => {
                abp.notify.info('Patching jobs enqueued');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchPanelServicesToOrganizationBenefitGroup() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch panel services to organisation benefit group?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchPanelServicesToOrganizationBenefitGroups()
              .success(() => {
                abp.notify.info('Patching jobs enqueued');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchHospitalizationServiceInpatientTicketItems() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm patch hospitalisation service inpatient ticket items?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchHospitalizationServiceInpatientTicketItems()
              .success(() => {
                abp.notify.info('Patching jobs enqueued');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function subsidiarySnapshotsInitialization() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm to initialise subsidiary snapshots',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .initializeSubsidarySnapshots()
              .success(() => {
                abp.notify.info('Initialisation of subsidiary snapshots is successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function patchSingaporeCities() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm to patch Singapore cities',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .patchSingaporeCities()
              .success(() => {
                abp.notify.info('Initialisation of patch Singapore cities is successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function retriggerMemberClaimCreateUpdateForMissingMemberClaimsInfo() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm to re-trigger member claim create update',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .retriggerMemberClaimCreateUpdateForMissingMemberClaimsInfo()
              .success(() => {
                abp.notify.info(
                  'Initialisation of re-trigger member claim create update successful'
                );
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function retriggerTicketBillingCreationForMissingPatientTicketBillingInfo() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm to re-trigger ticket billing creation',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .retriggerTicketBillingCreationForMissingPatientTicketBillingInfo()
              .success(() => {
                abp.notify.info(
                  'Initialisation of re-trigger ticket billing creation is successful'
                );
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function updateOpaBundleAsync() {
      $modal.open({
        templateUrl: require('./updateOpaBundle/updateOpaBundle.modal.html'),
        controller: 'host.views.debugTools.updateOpaBundle.modal as vm',
        backdrop: 'static',
      });
    }

    function updateZendeskOrganizationExternalIds() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm to update Zendesk Organisation External Ids?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .updateZendeskOrganizationExternalIds()
              .success(() => {
                abp.notify.info('Update Zendesk Organization External Ids is successful');
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function tagZendeskOrganizations() {
      vm.saving += 1;
      debugToolSvc
        .tagZendeskOrganizationsAsync()
        .success(() => {
          abp.notify.info('Zendesk organizations successfuly tagged.');
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function createZendeskOrganizationsByCountry() {
      $modal.open({
        templateUrl: require('./createZendeskOrganizationsByCountry/createZendeskOrganizationsByCountry.modal.html'),
        controller: 'host.views.debugTools.createZendeskOrganizationsByCountry.modal as vm',
        backdrop: 'static',
      });
    }

    function updateAdminRolePermissions() {
      if (vm.saving) return;

      abp.message.confirm(
        'Confirm enqueue update admin and HMS Personnel role permission jobs?',
        App.localize('AreYouSure'),
        (result) => {
          if (result && !vm.saving) {
            vm.saving += 1;
            debugToolSvc
              .updateAdminRolePermissions()
              .success(() => {
                abp.notify.info(
                  'Update admin and HMS Personnel role permissions jobs has been enqueued.'
                );
              })
              .finally(() => {
                vm.saving -= 1;
              });
          }
        }
      );
    }

    function regenerateServiceFeatureHeadcountFile() {
      $modal.open({
        templateUrl: require('./regenerateServiceFeatureHeadcountFile/regenerateServiceFeatureHeadcountFile.modal.html'),
        controller: 'host.views.debugTools.regenerateServiceFeatureHeadcountFile.modal as vm',
        backdrop: 'static',
      });
    }

    function patchNewEmployeeMovement() {
      $modal.open({
        templateUrl: require('./patchNewEmployeeMovement/patchNewEmployeeMovement.modal.html'),
        controller: 'host.views.debugTools.patchNewEmployeeMovement.modal as vm',
        backdrop: 'static',
      });
    }

    function regenerateSubscriptionReports() {
      $modal.open({
        templateUrl: require('./regenerateSubscriptionReports/regenerateSubscriptionReports.modal.html'),
        controller: 'host.views.debugTools.regenerateSubscriptionReports.modal as vm',
        backdrop: 'static',
      });
    }
  }
})();
