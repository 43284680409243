(() => {
  angular
    .module('app')
    .controller('modules.finance.views.myInvois.submissionResponseModal', SubmissionResponseModalController);

  SubmissionResponseModalController.$inject = ['$uibModalInstance', 'moment', 'responseBody'];

  function SubmissionResponseModalController($uibModalInstance, moment, responseBody) {
    const vm = this;

    vm.responseBody = responseBody;
    vm.close = close;

    function close() {
        $uibModalInstance.dismiss();
    }
  }
})();
