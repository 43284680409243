(function init() {
    angular.module('app').controller('host.views.debugTools.regenerateSubscriptionReports.modal', [
      '$uibModalInstance',
      'moment',
      'abp.services.revenue.subscription',
      function controller($uibModalInstance, moment, subscriptionSvc) {
        const vm = this;
        vm.saving = 0;
  
        vm.cancel = cancel;
        vm.confirm = confirm;
        vm.today = moment();
        vm.advancedFiltersAreShown = false;
         
        function cancel() {
          $uibModalInstance.dismiss();
        }
  
        function confirm() {
            vm.saving += 1;
            subscriptionSvc
            .regenerateReports({
              reportCycleCutOffEndDate: vm.reportCycleCutOffEndDate,
              reportCycleCutOffStartDate: vm.reportCycleCutOffStartDate,
              totalChargesThreshold: vm.totalChargesThreshold,
              regenerateCountLimit: vm.regenerateCountLimit
            })
            .success(() => {
                abp.notify.success(App.localize('SuccessfullyScheduled'));
            })
            .finally(() => {
                vm.saving -= 1;
                $uibModalInstance.dismiss();
            });
        }
      },
    ]);
  })();
  