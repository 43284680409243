import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.specialistClaims.processClaim', ProcessClaimPageController);

  ProcessClaimPageController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$filter',
    '$window',
    'abp.services.app.specialistClaim',
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
  ];

  function ProcessClaimPageController(
    $state,
    $stateParams,
    $uibModal,
    $filter,
    $window,
    specialistClaimSvc,
    constsPatientTicketItem,
    moment
  ) {
    const vm = this;

    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );
    vm.hasSpecialistEnhancement2_1 = abp.setting.getBoolean('Hms.Feature.SpecialistEnhancement2_1');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.loading = 0;
    vm.saving = 0;
    vm.hasSupportingDocuments = 0;
    vm.hasState = 1;
    vm.coPayLabel = App.localize('Copay');
    vm.canScheduleApproval = false;
    vm.isOpen = true;
    vm.isDraft = false;
    vm.filteredIcds = [];
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.approveChecklistItems = [
      {
        text: 'Amount correct',
      },
      {
        text: 'Detailed tax invoice',
      },
      {
        text: 'Patient Consent & Claim Form',
      },
      {
        text: 'Diagnosis form',
      },
      {
        text: 'Documents clear',
      },
    ];

    vm.claimNumber = $stateParams.claimNumber;
    vm.ticket = {
      items: [],
    };

    const approveMinRequirements = [
      vm.approveChecklistItems[0].text,
      vm.approveChecklistItems[1].text,
    ];

    vm.dateRangeOptions = {
      locale: {
        format: 'L',
        applyLabel: App.localize('Apply'),
        cancelLabel: App.localize('Cancel'),
        customRangeLabel: App.localize('CustomRange'),
      },
    };

    vm.diagnoses = {
      selected: [],
      data: [],
    };

    vm.viewClaimPolicy = viewClaimPolicy;
    vm.reject = reject;
    vm.rejectServiceRequest = rejectServiceRequest;
    vm.disablePartialApprove = disablePartialApprove;
    vm.canFullApprove = canFullApprove;
    vm.approve = approve;
    vm.cancelSchedule = cancelSchedule;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.saveDraft = saveDraft;

    vm.getTotalBillAmount = getTotalBillAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;
    vm.getCopaymentAmount = getCopaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getCollectCashAmount = getCollectCashAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getTopUpAmount = getTopUpAmount;
    vm.getEmployeeBalance = getEmployeeBalance;
    vm.getFinalGuaranteedAmount = getFinalGuaranteedAmount;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.hasTopUpCalculation = hasTopUpCalculation;
    vm.getPreviousCopaymentAmount = getPreviousCopaymentAmount;

    vm.showTopUpModeSelection = showTopUpModeSelection;
    vm.isActualVisitDateUpdated = isActualVisitDateUpdated;
    vm.getCurrentClaimAmount = getCurrentClaimAmount;
    vm.getCurrentUncoveredAmount = getCurrentUncoveredAmount;
    vm.getCurrentCoPay = getCurrentCoPay;
    vm.getCurrentExceededAmount = getCurrentExceededAmount;
    vm.getCurrentTotalCoveredAmount = getCurrentTotalCoveredAmount;
    vm.getCurrentCollectCashAmount = getCurrentCollectCashAmount;
    vm.getNewGlAmount = getNewGlAmount;
    vm.getNewExceededAmount = getNewExceededAmount;
    vm.getTopUpExceededAmount = getTopUpExceededAmount;
    vm.getNewTopUpAmount = getNewTopUpAmount;
    vm.addTreatmentCategory = addTreatmentCategory;
    vm.removeTreatmentCategory = removeTreatmentCategory;
    vm.clearTreatmentCategories = clearTreatmentCategories;
    vm.getTotalPayableAmount = getTotalPayableAmount;

    vm.addSection = addSection;
    vm.removeSection = removeSection;

    init();

    function init() {
      getClaimTicketForProcess();
    }

    function getClaimTicketForProcess() {
      vm.loading += 1;
      specialistClaimSvc
        .getClaimTicketForProcess({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.isCreate = !data.ticket.id;

          vm.claim = data.claim;
          vm.panel = data.panel;
          vm.patient = data.patient;
          vm.ticket = data.ticket;
          vm.topUpOption = data.topUpOption;
          vm.hasSupportingDocuments = !!(
            vm.claim.supportingDocumentAttachments && vm.claim.supportingDocumentAttachments.length
          );
          vm.hasUnresolvedQuery = data.claim.hasUnresolvedQuery;
          vm.benefit = data.benefit;
          vm.benefit.currentYear = moment().format('YYYY');
          vm.benefit.benefits = [];

          if (vm.hasInsuranceEnhancementModule) {
            vm.diagnoses.data = _.map(data.diseaseClassifications, (d) => ({
              code: d.code,
              description: d.description,
              displayName: `(${d.code}) ${d.description}`,
            }));

            vm.diagnoses.selected = _.map(data.selectedDiagnoses, (d) => ({
              code: d.code,
              description: d.description,
              displayName: d.code !== null ? `(${d.code}) ${d.description}` : `${d.description}`,
            }));

            vm.treatmentCategories = data.specialistTreatmentCategories;
            _.forEach(vm.ticket.items, (item) => {
              if (item.treatmentCategoryItems.length === 0) addTreatmentCategory(item);
            });

            vm.panelLocationDoctors = data.panelLocationDoctors;

            vm.isDraft = data.isDraft;
          }

          // If employee balance is unlimited and in exponential format,
          // force format it to max safe integer to prevent validation
          // error when passing to processing API input.

          if (vm.topUpOption != null) {
            if (vm.topUpOption.employeeBalanceIsUnlimited) {
              vm.topUpOption.employeeBalance = Number.MAX_SAFE_INTEGER;
            }
          }

          if (vm.benefit.balancePools && vm.benefit.balancePools.length) {
            _.each(vm.benefit.balancePools, (d) => {
              let title = App.localize('Specialist');

              // TODO: Better design? localisation required

              const isBudget = d.modifierType === 0 || d.modifierType === 2;
              let cycle = '';
              if (!d.isUnlimited) {
                switch (d.modifierCycle) {
                  case 0:
                    cycle = `every ${d.interval} years `;
                    break;
                  case 1:
                    cycle = 'annual ';
                    break;
                  case 2:
                    cycle = 'monthly ';
                    break;
                  case 3:
                    cycle = 'daily ';
                    break;
                  case 4:
                    cycle = 'per visit ';
                    break;
                  case 5:
                    cycle = '6 months ';
                    break;
                  case 6:
                    cycle = '4 months ';
                    break;
                  case 7:
                    cycle = '3 months ';
                    break;
                  case 8:
                    cycle = '2 months ';
                    break;

                  // no default
                }
                if (d.modifierType === 2 || d.modifierType === 3) cycle += 'overdraft ';
              }
              title += ` (${cycle}${isBudget ? 'budget' : 'visit'})`;

              const isEmptyExcess = d.isExcess && !d.employeePortion;
              if (!isEmptyExcess) {
                vm.benefit.benefits.push({
                  title,
                  isUnlimited: d.isUnlimited,
                  isExcess: d.isExcess,
                  isBudget,
                  limit: d.limit,
                  used: d.used,
                  locked: d.locked,
                  balance: d.balance,
                });
              }
            });
          }

          vm.benefit.allowance = Math.min(vm.benefit.allowance, vm.defaultMaxAllowance);

          if (!vm.ticket.id) {
            _.forEach(vm.ticket.items, (item) => {
              const d = item;
              if (!d.coveredAmount) d.coveredAmount = 0;
              if (!d.uncoveredAmount) d.uncoveredAmount = 0;
            });
          }

          // Copay label.

          if (vm.claim.coPayValue) {
            let label = '';
            if (vm.claim.isCoPayPercentage) {
              label = `${vm.claim.coPayValue}%`;
              vm.coPayValueDisplay = App.localize('CopayPercentage', label);
            } else {
              label = $filter('currencyFormat')(vm.claim.coPayValue, vm.currencyCode);
              vm.coPayValueDisplay = App.localize('CopayFixedAmount', label);
            }
            vm.coPayLabel = App.localize('CopayX', label);
          } else {
            vm.coPayValueDisplay = App.localize('NotApplicable');
          }
          // Record total covered amount to calculate the previous exceed amount before adjustment.

          if (vm.claim.requestType === 6) {
            vm.previousTotalCoveredAmount = getTotalCoveredAmount();
          }

          vm.claim.panelCoPay = vm.hasSpecialistEnhancement2_1
            ? getCopaymentAmount()
            : calcCoPay(vm.claim.billAmount);

          vm.claim.panelExceededAmount = vm.hasSpecialistEnhancement2_1
            ? getExceededAmount(getTotalCoveredAmount(), false)
            : calcExceededAmount(vm.claim.billAmount);

          if (vm.ticket.id && vm.claim.requestType !== 6) {
            _.each(vm.approveChecklistItems, (item) => {
              const d = item;
              if (approveMinRequirements.indexOf(d.text) !== -1) {
                d.disabled = true;
                d.isChecked = true;
              }
            });
          }

          if (vm.ticket.medicalCertificate) {
            vm.medicalCertificate = {
              startDate: vm.ticket.medicalCertificate.startDate,
              endDate: vm.ticket.medicalCertificate.endDate,
              remark: vm.ticket.medicalCertificate.remark,
            };
          }

          if (!vm.claim.formABAttachments || !vm.claim.formABAttachments.length) {
            for (let i = 0; i < vm.approveChecklistItems.length; i += 1) {
              if (vm.approveChecklistItems[i].text === 'Patient Consent & Claim Form') {
                vm.approveChecklistItems[i].disabled = false;
                vm.approveChecklistItems[i].isChecked = false;
                break;
              }
            }
          }
          vm.showTopUpColumn = vm.hasSpecialistEnhancement2_1
            ? vm.topUpOption
            : vm.topUpOption && calcExceededAmount(vm.claim.billAmount) > 0;

          vm.topUpNotPossible =
            vm.showTopUpColumn &&
            vm.topUpOption != null &&
            vm.claim.guaranteedAmount >
              Math.min(vm.topUpOption.corporateBalance, vm.topUpOption.employeeBalance);

          if (!vm.showTopUpColumn) {
            vm.hasScheduledApproval = !!vm.claim.scheduledApprovalDate;

            const day3 = App.addBusinessDays(moment(vm.claim.creationTime).startOf('day'), 3);
            vm.canScheduleApproval = day3 > moment().startOf('day');
            vm.scheduledApprovalDate = vm.hasScheduledApproval
              ? moment(vm.claim.scheduledApprovalDate).startOf('day')
              : day3;

            const status =
              vm.claim.specialistClaimScheduledApprovalStatus === 2 ||
              vm.claim.scheduledApprovalStatus === 5
                ? App.localize('PartialApproval')
                : App.localize('FullApproval');
            vm.hasScheduledApprovalNarrative = App.localize(
              'SpecialistClaimHasScheduledApprovalNarrative1',
              status.toLowerCase(),
              moment(vm.scheduledApprovalDate).format('L')
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // To indicate if allowance or inital guaranteed amount will be used as a limit.

    function hasTopUpCalculation() {
      if (!vm.claim) return false;

      return vm.topUpMode === 1 || vm.claim.requestType === 6;
    }

    /* Amounts Calculation */

    // Total bill amount.

    function getTotalBillAmount() {
      let totalBillAmount = 0;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (p) => {
          totalBillAmount += p.coveredAmount + p.uncoveredAmount;
        });
      });
      return App.roundAmount(totalBillAmount);
    }

    // Total sum of all the covered patient ticket items.
    // Use to calculate copayment purpose only.

    function getTotalCoveredAmount() {
      let amount = 0;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (p) => {
          amount += p.coveredAmount;
        });
      });
      return App.roundAmount(amount);
    }

    function getTotalPayableAmount(totalCoveredAmount, hasTopUp) {
      return App.roundAmount(
        getCoveredAmount() - 
        getExceededAmount(totalCoveredAmount, hasTopUp));
    }

    // Total uncovered amount.

    function getTotalUncoveredAmount() {
      return getTotalBillAmount() - getTotalCoveredAmount();
    }

    // Calculate copayment.
    // If copayment is percentage, copayment is calculated based on
    // total patient ticket items covered amount.

    function getCopaymentAmount() {
      if (!vm.claim || !vm.claim.coPayValue) return 0;

      let copay = vm.claim.coPayValue;
      const totalCoveredAmount = getTotalCoveredAmount();

      if (vm.claim.isCoPayPercentage) copay = (totalCoveredAmount * vm.claim.coPayValue) / 100;
      else copay = Math.min(totalCoveredAmount, copay);

      return App.roundAmount(copay);
    }

    // Get previous copayment value (only applicable for adjustment request)

    function getPreviousCopaymentAmount(previousGuaranteedAmount) {
      let copayment = 0;
      if (vm.claim.coPayValue) {
        copayment = vm.claim.isCoPayPercentage
          ? (previousGuaranteedAmount * vm.claim.coPayValue) / 100
          : vm.claim.coPayValue;
      }
      return copayment;
    }

    // Calculate the eligible claim amount.

    function getCoveredAmount() {
      const totalCoveredAmount = getTotalCoveredAmount();
      const copay = getCopaymentAmount();

      const coveredAmount = totalCoveredAmount - copay;

      return App.roundAmount(coveredAmount);
    }

    // Calculate exceeded amount if covered amount > entitlement.

    function getExceededAmount(totalCoveredAmount, hasTopUp) {
      if (!vm.claim) return 0;

      const coveredAmount = totalCoveredAmount - getCopaymentAmount();

      const entitlement = getEntitledAmount(hasTopUp);

      let exceededAmount = 0;

      if (coveredAmount > entitlement) {
        exceededAmount = coveredAmount - entitlement;
      }

      return App.roundAmount(exceededAmount);
    }

    // Calculate entitled amount.

    function getEntitledAmount(hasTopUp) {
      if (!vm.claim) return 0;

      let entitledAmount =
        vm.topUpOption != null && (hasTopUp || vm.claim.isLateSubmission)
          ? Math.max(vm.topUpOption.employeeBalance, vm.claim.guaranteedAmount)
          : vm.claim.guaranteedAmount;

      const coveredAmount = vm.getCoveredAmount(vm.hasTopUpCalculation());
      if (entitledAmount > coveredAmount) entitledAmount = coveredAmount;

      return App.roundAmount(entitledAmount);
    }

    // Calculate the collect cash amount.

    function getCollectCashAmount(hasTopUp) {
      const uncoveredAmount = getTotalUncoveredAmount();
      const exceededAmount = getExceededAmount(getTotalCoveredAmount(), hasTopUp);
      const copaymentAmount = getCopaymentAmount();
      return uncoveredAmount + exceededAmount + copaymentAmount;
    }

    // Calculate top up amount.

    function getTopUpAmount() {
      const totalCoveredAmount = getTotalCoveredAmount() || 0;
      const initialGuaranteedAmount = vm.claim.guaranteedAmount;
      const topUpAMount = totalCoveredAmount - initialGuaranteedAmount;
      return App.roundAmount(Math.max(topUpAMount, 0));
    }

    // Calculate employee balance for top-up.

    function getEmployeeBalance() {
      const corporateBalance = vm.topUpOption ? vm.topUpOption.corporateBalance : 0;
      const employeeBalance = vm.topUpOption ? vm.topUpOption.employeeBalance : 0;
      return App.roundAmount(Math.min(corporateBalance, employeeBalance));
    }

    // Calculate final guaranteed amount.

    function getFinalGuaranteedAmount(limit, hasTopUp) {
      let guaranteedAmount = getCoveredAmount(hasTopUp) || 0;
      if (guaranteedAmount > limit) guaranteedAmount = limit;
      return App.roundAmount(guaranteedAmount);
    }

    function areFieldsValid() {
      let isValid = true;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (d) => {
          if (
            typeof d.coveredAmount === 'undefined' ||
            d.coveredAmount === null ||
            typeof d.uncoveredAmount === 'undefined' ||
            d.uncoveredAmount === null
          ) {
            isValid = false;
          }
        });
      });
      return isValid;
    }

    function isTotalBillAmountValid() {
      if (!areFieldsValid()) return false;

      const billAmount =
        vm.claim.requestType === 6 && vm.claim.adjustedFinalBillAmount
          ? vm.claim.adjustedFinalBillAmount
          : vm.claim.billAmount;

      return vm.getTotalBillAmount() === billAmount;
    }

    /* Approval Checklist */

    function showTopUpModeSelection() {
      if (vm.hasSpecialistEnhancement2_1)
        return (
          vm.showTopUpColumn &&
          getFinalGuaranteedAmount(getEmployeeBalance(), true) > vm.claim.guaranteedAmount &&
          !vm.claim.isLateSubmission &&
          vm.claim.requestType !== 6
        );
      return (
        vm.showTopUpColumn &&
        vm.getNewGlAmount() > vm.claim.guaranteedAmount &&
        !vm.claim.isLateSubmission &&
        vm.claim.requestType !== 6
      );
    }

    function isMinRequirement(target) {
      return _.some(approveMinRequirements, (d) => d === target);
    }

    function disablePartialApprove() {
      let requireChecked = 0;
      _.each(vm.approveChecklistItems, (d) => {
        if (isMinRequirement(d.text) && d.isChecked) {
          requireChecked += 1;
        }
      });
      return approveMinRequirements.length !== requireChecked;
    }

    function canFullApprove() {
      const cnt = _.filter(vm.approveChecklistItems, (d) => d.isChecked).length;
      return cnt === vm.approveChecklistItems.length;
    }

    function isActualVisitDateUpdated() {
      return (
        moment(vm.ticket.ticketDate).format('L') !== moment(vm.ticket.actualVisitDate).format('L')
      );
    }

    /* End of Approval Checklist */

    /* Approval Process */

    function showRejectModal(isTopUpRejection, callback) {
      $uibModal
        .open({
          templateUrl: require('./rejectModal.html'),
          controller: 'common.views.specialistClaims.processClaim.rejectModal as vm',
          backdrop: 'static',
          resolve: {
            claimNumber() {
              return vm.claimNumber;
            },
            isTopUpRejection() {
              return isTopUpRejection;
            },
          },
        })
        .result.then((remarks) => {
          if (callback) callback(remarks);
        });
    }

    function partialApprove(input) {
      if (vm.saving) return;
      vm.saving += 1;
      specialistClaimSvc
        .partialApproveClaim(input)
        .success((data) => {
          successApproveModal(data, input.scheduleApproval, false);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function approveAdjustment(input) {
      if (vm.saving) return;
      vm.saving += 1;
      specialistClaimSvc
        .approveAdjustment(input)
        .success(() => {
          successApproveModal(null, false, true);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function fullApprove(input) {
      if (vm.saving) return;
      vm.saving += 1;
      specialistClaimSvc
        .fullApproveClaim(input)
        .success((data) => {
          successApproveModal(data, input.scheduleApproval, true);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function successApproveModal(data, hasScheduleApproval, isFullApproval) {
      if (vm.hasSpecialistWorkflowEnhancement) {
        let titleText;
        if (hasScheduleApproval) titleText = App.localize('SuccessfullyScheduled');
        else if (isFullApproval) titleText = App.localize('SuccessfullyApproved');
        else titleText = App.localize('SuccessfullyPartialApproved');

        const claimNumberHtml = `<strong>${vm.claimNumber}</strong>`;

        let bodyText;
        if (!hasScheduleApproval) bodyText = App.localize('ClaimApprovedNarrative');
        else if (isFullApproval)
          bodyText = App.localize('ClaimScheduledFullApproveNarrative', claimNumberHtml);
        else bodyText = App.localize('ClaimScheduledPartialApproveNarrative', claimNumberHtml);

        swal(
          {
            title: `${titleText}!`,
            text: bodyText,
            type: 'success',
            showCancelButton: true,
            closeOnConfirm: false,
            confirmButtonColor: '#1ab394',
            confirmButtonText: App.localize('ViewClaim'),
            cancelButtonText: App.localize('ReturnToClaimsListing'),
            html: true,
          },
          (isViewClaim) => {
            if (isViewClaim) {
              $state.go('specialistClaimRequestDetail', {
                claimNumber: vm.claimNumber,
                timeZoneId: vm.timeZoneId,
              });
            } else {
              $state.go('specialistClaims');
            }
            swal.close();
          }
        );
      } else {
        successModal(data);
        abp.notify.info(App.localize('SuccessfullyApproved'));
        $window.history.back();
      }
    }

    function partialOrFullApprove(isFullApprove, input) {
      const i = input;
      vm.remarks = _.trim(vm.remarks);
      if (vm.remarks) {
        i.remarks = (i.remarks ? `${i.remarks}. ` : '') + vm.remarks;
      }

      if (vm.claim.requestType === 6) {
        approveAdjustment(i);
      } else if (isFullApprove) fullApprove(i);
      else partialApprove(i);
    }

    function approve(isFullApprove) {
      if (vm.saving) return;

      let documents = [];
      if (vm.claim.documents) {
        documents = _.map(vm.claim.documents, (d) => ({
          externalObjectId: d.externalObjectId,
          isApplied: d.applied,
        }));
      }

      const input = {
        id: vm.claimNumber,
        diagnoses: vm.diagnoses.selected,
        treatmentItems: vm.ticket.items,
        topUpRequest: null,
        scheduleApproval: vm.scheduleApproval,
        medicalCertificate: vm.medicalCertificate,
        actualVisitDate: vm.ticket.actualVisitDate,
        actualVisitRemark: vm.isActualVisitDateUpdated() ? vm.ticket.actualVisitRemark : null,
        documents,
        adjustedFinalBillAmount: vm.claim.adjustedFinalBillAmount,
        coveredAmount: getTotalPayableAmount(getTotalCoveredAmount(), hasTopUpCalculation()),
        employeeBalance: vm.topUpOption ? vm.topUpOption.employeeBalance : 0,
      };

      if (vm.showTopUpModeSelection()) {
        input.topUpRequest = {
          topUpIfPossible: vm.topUpMode === 1,
        };
      }

      if (input.topUpRequest !== null && !input.topUpRequest.topUpIfPossible) {
        showRejectModal(true, (remarks) => {
          abp.message.confirm(
            App.localize('RejectTopUpAndApproveXConfirmMessage', vm.claimNumber),
            App.localize('AreYouSure'),
            (d) => {
              if (d) {
                input.topUpRequest.topUpRejectionRemarks = remarks;
                partialOrFullApprove(isFullApprove, input);
              }
            }
          );
        });
      } else {
        const key = isFullApprove ? 'FullApproveXConfirmMessage' : 'PartialApproveXConfirmMessage';
        const warningMsg = [];
        if (!vm.ticket.id && getTotalBillAmount() !== vm.claim.billAmount) {
          warningMsg.push(App.localize('SpecialistClaimClaimAmountWarningMessage'));
        }

        if (warningMsg.length) {
          warningMsg.push(App.localize(key, vm.claimNumber));

          swal(
            {
              title: App.localize('AreYouSure'),
              text: warningMsg.join(' '),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              inputPlaceholder: App.localize('PleaseExplain'),
            },
            (inputValue) => {
              if (inputValue === false) return false;

              const trimInput = $.trim(inputValue || '');
              if (trimInput === '') {
                swal.showInputError(App.localize('InvalidInput'));
                return false;
              }

              // Approve claim.

              const inputVal = trimInput.charAt(0).toUpperCase() + trimInput.slice(1);
              if (input.topUpRequest !== null && input.topUpRequest.topUpIfPossible) {
                input.topUpRequest.topUpRejectionRemarks = inputVal;
              } else {
                input.remarks = inputVal;
              }

              partialOrFullApprove(isFullApprove, input);

              swal.close();
              return true;
            }
          );
        } else {
          swal(
            {
              title: App.localize('ConfirmApproveClaim'),
              text: App.localize('SentToSystemAndCannotBeUndoneNarrative'),
              type: 'warning',
              confirmButtonColor: '#1ab394',
              confirmButtonText: App.localize('Yes'),
              cancelButtonText: App.localize('No'),
              showCancelButton: true,
              closeOnConfirm: false,
            },
            (isConfirm) => {
              if (isConfirm) {
                partialOrFullApprove(isFullApprove, input);
              }
              swal.close();
            }
          );
        }
      }
    }

    function cancel(remarks) {
      if (vm.saving) return;
      vm.saving += 1;
      specialistClaimSvc
        .cancelScheduledApproval({
          id: vm.claimNumber,
          remarks,
        })
        .success(() => {
          abp.notify.info(App.localize('SuccessfullyCancelled'));
          getClaimTicketForProcess();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancelSchedule() {
      swal(
        {
          title: App.localize('CancelScheduledApproval'),
          text: vm.hasSpecialistWorkflowEnhancement
            ? App.localize('PurposeOfCancellation')
            : App.localize('WhyCancel'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
          confirmButtonText: App.localize('Confirm'),
          cancelButtonText: App.localize('Cancel'),
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if ($.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }
          cancel($.trim(inputValue));
          swal.close();
          return true;
        }
      );
    }

    function reject() {
      if (vm.saving) return;

      showRejectModal(false, (remarks) => {
        abp.message.confirm(
          App.localize('RejectXConfirmMessage', vm.claimNumber),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              vm.remarks = remarks;
              rejectClaim();
            }
          }
        );
      });
    }

    function rejectServiceRequest() {
      if (vm.saving) return;

      // This is to make the remarks input box redline
      // if is not filled for new reject implementation.

      if (vm.hasSpecialistWorkflowEnhancement && !vm.editForm.remark.$touched) {
        vm.editForm.remark.$setTouched();
        return;
      }

      if (vm.hasSpecialistWorkflowEnhancement && vm.editForm.remark.$invalid) return;

      swal(
        {
          title: `${App.localize('RejectClaim')}?`,
          text: App.localize('SentToSystemAndCannotBeUndoneNarrative'),
          type: 'warning',
          confirmButtonColor: '#ED5565',
          confirmButtonText: App.localize('Yes'),
          cancelButtonText: App.localize('No'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        function (isConfirm) {
          swal.close();
          if (isConfirm) {
            if (vm.claim.requestType === 6) {
              rejectAdjustment();
            } else {
              rejectClaim();
            }
          }
        }
      );
    }

    function rejectAdjustment() {
      vm.saving += 1;
      specialistClaimSvc
        .rejectAdjustment({
          id: vm.claimNumber,
          remarks: vm.remarks,
        })
        .success(() => {
          showSuccessRejectModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function rejectClaim() {
      vm.saving += 1;
      specialistClaimSvc
        .rejectClaim({
          id: vm.claimNumber,
          remarks: vm.remarks,
        })
        .success(() => {
          if (vm.hasSpecialistWorkflowEnhancement) {
            showSuccessRejectModal();
          } else {
            abp.notify.info(App.localize('SuccessfullyRejected'));
            $window.history.back();
          }
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showSuccessRejectModal() {
      swal(
        {
          title: `${App.localize('SuccessfullyRejected')}!`,
          text: App.localize('SuccessfullyRejectedSpecialistClaimNarrative'),
          html: true,
          type: 'success',
          confirmButtonColor: '#23c6c8',
          confirmButtonText: App.localize('ViewClaim'),
          cancelButtonText: App.localize('ReturnToClaimList'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        function (claimDetail) {
          swal.close();

          if (claimDetail) {
            $state.go('specialistClaimRequestDetail', {
              claimNumber: vm.claimNumber,
              timeZoneId: vm.timeZoneId,
            });
          } else {
            $state.go('specialistClaims');
          }
        }
      );
    }

    /* End of Approval Process */

    function successModal(data) {
      $uibModal.open({
        templateUrl: require('./successModal.html'),
        controller: 'common.views.specialistClaims.processClaim.successModal as vm',
        backdrop: 'static',
        resolve: {
          claimOutput() {
            return data;
          },
          claimNumber() {
            return vm.claimNumber;
          },
        },
      });
    }

    /* Medical certificate */

    function updateMcRemarks() {
      if (vm.medicalCertificate) {
        const remark = _.chain(vm.ticket.treatments)
          .filter((t) => t.treatment && t.treatment.displayName)
          .map((t) => t.treatment.displayName)
          .join(', ')
          .value();
        vm.medicalCertificate.remark = remark;
      }
    }

    function issueMc() {
      const firstDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
      updateMcRemarks();
    }

    function removeMc() {
      vm.medicalCertificate = null;
    }

    function getMcDurationDescription() {
      if (vm.medicalCertificate) {
        const startDate = moment(vm.medicalCertificate.startDate);
        const endDate = moment(vm.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    /* End of Medical certificate */

    /* Functions */

    function viewClaimPolicy() {
      $uibModal.open({
        templateUrl: require('./claimPolicy.modal.html'),
        controller: 'common.views.specialistClaim.processClaim.claimPolicyModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          memberId() {
            return vm.patient.id;
          },
        },
      });
    }
    /* End of Functions */

    // TO DO: Functions to be removed after specialist enhancement 2.1 is turned on.

    function calcExceededAmount(amount) {
      if (!vm.claim) return 0;
      const coveredAmount = amount - calcCoPay(amount);
      return App.roundAmount(Math.max(coveredAmount - vm.claim.guaranteedAmount, 0));
    }

    function calcCoPay(amount) {
      if (!vm.claim || !vm.claim.coPayValue) return 0;
      let coPay = vm.claim.coPayValue;
      if (vm.claim.isCoPayPercentage) {
        coPay = (amount * vm.claim.coPayValue) / 100;
      } else {
        coPay = Math.min(amount, coPay);
      }
      return App.roundAmount(coPay);
    }

    function getCurrentClaimAmount() {
      return getTotalCoveredAmount();
    }

    function getCurrentUncoveredAmount() {
      return getTotalUncoveredAmount();
    }

    function getCurrentCoPay() {
      return getCopaymentAmount();
    }

    function getCurrentExceededAmount() {
      return calcExceededAmount(getTotalCoveredAmount());
    }

    function getCurrentTotalCoveredAmount() {
      if (!vm.claim) return 0;
      return App.roundAmount(vm.getTotalBillAmount() - vm.getCurrentCollectCashAmount());
    }

    function getCurrentCollectCashAmount() {
      if (!vm.claim) return 0;

      return App.roundAmount(
        vm.getCurrentCoPay() + vm.getCurrentUncoveredAmount() + vm.getNewExceededAmount()
      );
    }

    function getNewGlAmount() {
      if (!vm.showTopUpColumn && vm.claim.requestType !== 6) return 0;

      return App.roundAmount(
        vm.getTotalBillAmount() - vm.getCurrentCollectCashAmount() + vm.getNewTopUpAmount()
      );
    }

    function getNewExceededAmount() {
      let exceedAmount = 0;
      if (vm.claim) {
        exceedAmount =
          vm.getCurrentClaimAmount() - vm.getCurrentCoPay() - vm.claim.guaranteedAmount;
      }

      return App.roundAmount(Math.max(exceedAmount, 0));
    }

    function getTopUpExceededAmount() {
      const newGuaranteeAmount = vm.claim.guaranteedAmount + vm.getNewTopUpAmount();
      const exceededAmount = vm.getCurrentClaimAmount() - vm.getCurrentCoPay() - newGuaranteeAmount;
      return App.roundAmount(Math.max(exceededAmount, 0));
    }

    function getNewTopUpAmount() {
      const amt = vm.getCurrentClaimAmount() || 0;
      return App.roundAmount(
        Math.max(
          Math.min(
            amt - vm.getCurrentCoPay() - vm.claim.guaranteedAmount,
            vm.claim.panelExceededAmount
          ),
          0
        )
      );
    }

    function addTreatmentCategory(record) {
      const r = record;

      if (!r.treatmentCategoryItems) {
        r.treatmentCategoryItems = [];
      }

      r.treatmentCategoryItems.push({
        code: r.code,
        coveredRemarks: r.coveredRemarks,
        coveredAmount: r.coveredAmount,
        uncoveredRemarks: r.uncoveredRemarks,
        uncoveredAmount: r.uncoveredAmount,
      });
    }

    function removeTreatmentCategory(record, treatmentId) {
      const r = vm.ticket.items[record];
      r.treatmentCategoryItems.splice(treatmentId, 1);
    }

    function clearTreatmentCategories(category) {
      const c = category;
      c.treatmentCategoryItems = [];
      addTreatmentCategory(c);
    }

    function addSection() {
      let baseItem = {};

      if (vm.ticket.items.length > 0) {
        [baseItem] = vm.ticket.items;
      }

      vm.ticket.items.push({
        treatmentId: baseItem.treatmentId,
        treatmentName: baseItem.treatmentName,
        remarks: null,
        coveredAmount: 0,
        uncoveredRemarks: null,
        doctorId: null,
        doctorName: null,
        treatmentCategories: [{}],
      });

      addTreatmentCategory(vm.ticket.items.at(-1));
    }

    function removeSection(sectionId) {
      vm.ticket.items.splice(sectionId, 1);
    }

    function saveDraft() {
      if (vm.saving) return;

      let documents = [];
      if (vm.claim.documents) {
        documents = _.map(vm.claim.documents, (d) => ({
          externalObjectId: d.externalObjectId,
          isApplied: d.applied,
        }));
      }

      const input = {
        id: vm.claimNumber,
        diagnoses: vm.diagnoses.selected,
        treatmentItems: vm.ticket.items,
        topUpRequest: null,
        scheduleApproval: vm.scheduleApproval,
        medicalCertificate: vm.medicalCertificate,
        actualVisitDate: vm.ticket.actualVisitDate,
        actualVisitRemark: vm.isActualVisitDateUpdated() ? vm.ticket.actualVisitRemark : null,
        documents,
        adjustedFinalBillAmount: vm.claim.adjustedFinalBillAmount,
        coveredAmount: getCoveredAmount(),
        employeeBalance: vm.topUpOption ? vm.topUpOption.employeeBalance : 0,
      };

      specialistClaimSvc
        .saveDraft(input)
        .success(() => {
          const titleText = App.localize('SuccessfullySavedDraft');

          swal(
            {
              title: `${titleText}!`,
              type: 'success',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              confirmButtonText: App.localize('ViewClaim'),
              cancelButtonText: App.localize('ReturnToClaimsListing'),
              html: true,
            },
            (isViewClaim) => {
              if (isViewClaim) {
                $state.go('specialistClaimRequestDetail', {
                  claimNumber: vm.claimNumber,
                  timeZoneId: vm.timeZoneId,
                });
              } else {
                $state.go('specialistClaims');
              }
              swal.close();
            }
          );
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }
  }
})();
